/*==================================
    29. FAQ Area Css
====================================*/

.faq-box-wrap {
    margin-top: 100px;
    margin-bottom: 100px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
.faq-section-title { 
    margin-top: 30px;
    .title {
        font-size: 62px;
        font-weight: 400;
        margin-top: 100px;
        margin-right: 30px;
        .bold-text {
            font-weight: 600;
            display: block;
        }
        @media #{$desktop-device} {
            font-size: 52px;
            margin-top: 50px;
        }
        @media #{$tablet-device} {
            font-size: 48px;
            margin-top: 50px;
        }
        @media #{$large-mobile} {
            font-size: 42px;
            margin-top: 50px;
        }
        @media #{$small-mobile} {
            font-size: 32px;
            margin-top: 50px;
        }
    }   
}

.faq-content-wrap {
    border-left: 1px solid #ddd;
    
}
.faq-qustion {
    background: transparent;
    border: none;
    font-weight: 600;
    padding: 40px 0 40px 40px;
    font-size: 20px;
    @media #{$small-mobile} {
        font-size: 14px;
        padding: 20px 0 20px 10px;
    }
    &::after {
        display: none;
    }
    &:focus {
        outline: 0;
        box-shadow: none;
    }
    .number-of-accordion {
        min-height: 46px;
        min-width: 46px;
        text-align: center;
        line-height: 46px;
        background-color: #f4f4f4;
        border-radius: 10px;
        margin-right: 20px;
        font-size: 15px;
    }
}
.faq-qustion:not(.collapsed) {
    background-color: transparent;
    box-shadow: none;
    color: $black;
    .number-of-accordion {
        background-color: $primary;
        color: $white;
    }
}
.accordion-item {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #ddd;
    &:last-child {
        border-bottom: none;
    }
}
.faq-ans {
    padding: 0 0 40px 100px;
    @media #{$small-mobile} {
        padding: 0 0 20px 10px;
    }
}