/*==================================
    28. Author Post Area Css
====================================*/
.messonry-button {
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
    padding-top: 20px;
    border-top: 1px solid #eee;
    button {
        background-color: transparent;
        border: none;
        font-weight: 600;
        padding-left: 35px;
        margin-left: 30px;
        position: relative;
        &.is-checked {
            color: $primary;
        }
        &::before {
            color: #222;
            content: '';
            height: 6px;
            width: 6px;
            background: #222;
            border-radius: 5000px;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            left: 0%;
        }
        &:first-child {
            padding-left: 0px;
            margin-left: 0px;
            &::before {
                display: none;
            }
        }
    }
}
.author-blog-post-content {
    .post-right-side {
        display: flex;
        span {
            position: relative;
            padding-right: 15px;
            margin-right: 15px;
            font-size: 13px;
            &::after {
                position: absolute;
                content: "";
                right: -0px;
                top: 50%;
                height: 4px;
                width: 4px;
                background: $black;
                border-radius: 50000px;
                transform: translateY(-50%);
            }
            a {
                &:hover {
                    color: $primary;
                }
            }
            &:last-child {
                padding-right: 0;
                margin-right: 0;
                &::after {
                    display: none;
                }
            } 
        }
    }
    
    @media #{$large-mobile} {
        flex-direction: column;
    }
}
.author-blog-post-wrap {
    margin-bottom: 30px;
}

.author-post-bottom-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    margin-top: 20px;
}

.author-blog-thum {
    display: block;
    img {
        width: 100%;
    }
}

.author-post-action-box {
    display: flex;
    padding: 0px 20px;
    margin: 20px 0;
    @media #{$small-mobile} {
        padding: 0px 0px;
    }
    .author-action {
        margin-right: 10px;
        margin-left: 10px;
    }
}

