/*==================================
    30. Contact us page Css
====================================*/
.office-img {
    margin-top: 30px;
    padding: 30px;
    background: #f7f7f7;
    border-radius: 15px;
    position: relative;
    img {
        border-radius: 15px;
    }
    .office-title {
        position: absolute;
        bottom: 60px;
        text-align: center;
        left: 50%;
        display: inline-block;
        background-color: #333;
        border-radius: 15px;    
        transform: translateX(-50%);
        color: $white;
        padding: 10px 40px;
        border: 4px solid rgba(255, 255, 255, 0.8);
    }
}

.single-office-info {
    margin-top: 30px;
    padding: 40px 55px;
    border: 1px solid #ddd;
    border-radius: 15px;
    background-color: transparent;
    @media #{$tablet-device,$large-mobile} {
        padding: 30px 25px;
    }
    .single-contact-info {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
        .icon {
            min-height: 45px;
            min-width: 45px;
            line-height: 45px;
            border-radius: 10px;
            margin-right: 20px;;
            text-align: center;
            background-color: #f4f4f4;
        }
    }
}

.single-office-info-wrap {
    &:hover {
        .office-title {
            background-color: $primary;
            color: #222;
        }
        .single-office-info {
            border-color: $primary;
        }
    }
}

.contact-from {
    .section-title {
        .title {
            font-size: 40px;
            @media #{large-mobile} {
                font-size: 30px;
            }
        }
    }
}

.single-input-box {
    margin-bottom: 20px;
    input,textarea {
        border: 1px solid #efefef;
        width: 100%;
        padding: 10px 30px;
        border-radius: 10px;
        background-color: #fafafa;
    }
    textarea {
        height: 200px;
    }
    &:hover {
        input,textarea {
            background-color: transparent;
        }
    }
}


.contact-us-map iframe {
    height: 664px;
    width: 100%;
    border-radius: 15px;
}



