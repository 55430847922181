/*==================================
    21. Add Banner Area Css
====================================*/

.bottom-add-banner-box{
    position: relative;
    &a {
        display: block;
    }
    @media #{$large-mobile} {
        img {
            height: 100px;
            object-fit: cover;
            border-radius: 10px;
        }
    }
}

.bottom-add-text {
    font-size: 25px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 50px;
    display: inline-block;
    span {
        display: block;
        font-size: 32px;
        text-align: left;
        margin-top: 3px;
    }
    @media #{$large-mobile} {
        font-size: 16px;
        span {
            font-size: 18px;
        }
    }
}