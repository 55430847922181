
/*========================================
 =        09. Header Css            =
==========================================*/
.header-sticky.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-animation: .95s ease-in-out 0s normal none 1 running fadeInDown;
            animation: .95s ease-in-out 0s normal none 1 running fadeInDown;
    z-index: 999;
    -webkit-transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
.position--absolute {
    position: absolute;
    width: 100%;
    z-index: 5;
}
.mobile-menu-right {
    margin-right: 0px;
    float: right;
}
.header-top-area {
    background-color: #22262a;
    padding-bottom: 10px;
}
.header-top-menu-list {
    margin-top: 10px;
    li {
        display: inline-block;
        padding-right: 20px;
        margin-right: 15px;
        position: relative;
        &::after {
            position: absolute;
            content: "";
            right: -0px;
            top: 50%;
            height: 4px;
            width: 4px;
            background: #ffc4a0;
            border-radius: 50000px;
            transform: translateY(-50%);
        }
        a {
            color: $white;
            &:hover {
                color: $primary;
            }
        }
        &:last-child {
            padding-right: 0;
            margin-right: 0;
            &::after {
                display: none;
            }
        }
    }
    @media #{$small-mobile} {
        text-align: center;
    }
}

//  Header Top Contact Info

.header-top-contact-info {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}
.header-top-single-contact-item {
    display: flex;
    align-items: center;
    color: $white;
    margin: 0 30px;
    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
    }
    .text-size-small {
        font-size: 12px;
    }
    @media #{$small-mobile} {
        margin: 0 5px;
        font-size: 13px;
    }
}
.header-top-contact-icon {
    margin-right: 15px;
    @media #{$small-mobile} {
        margin-right: 5px;
    }
}




.header-top-right-side {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $primary;
    p {
        margin-bottom: 0;
    }
    .wayder {
        &-icon {
            margin: 0 10px;
        }
    }
    @media #{$small-mobile} {
        justify-content: center;
    }
}




.header-mid-area {
    margin-top: 20px;
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 20px;
}
.header-mid-right-side {
    display: flex;
    justify-content: flex-end;
}
.single-action-item {
    height: 45px;
    min-width: 45px;
    background: #f4f4f4;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 20px;
    border-radius: 5px;
    &:hover {
        background: $primary;
    }
    &:first-child {
        margin-left: 0;
    }
    @media #{$desktop-device} {
        height: 40px;
        min-width: 40px;
        margin-left: 12px
    }
    @media #{$large-mobile,$tablet-device} {
        height: 40px;
        min-width: 40px;
        margin-left: 7px
    }
}

.header-add-banner {
    position: relative;
    a {
        display: block;
    }
}
.header-add-text {
    font-size: 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50px;
    display: inline-block;
    span {
        display: block;
        font-size: 18px;
        text-align: left;
        margin-top: 3px;
        font-weight: bold;
    }
}

/// Header Bottom Area Css

.social-share-area {
    li {
        display: inline-block;
        margin-right: 15px;
        &:last-child {
            margin-right: 0;
        }
        a {
            height: 45px;
            width: 45px;
            line-height: 45px;
            text-align: center;
            background-color: #ddd;
            border-radius: 5px;
            &:hover {
                background-color: $primary;
                color: $white;
            }
        }
        @media #{$large-mobile} {
            margin-right: 10px;
        }
        @media #{$small-mobile} {
            margin-right: 6px;
        }
    }
    &.social-share-border-outline {
        li {
            a {
                background-color: transparent;
                border-radius: 15px;
                border: 1px solid #fff;
                color: $white;
                &:hover {
                    background: #fff;
                    color: #222;
                }
            }
        }
    }
    &.social-share-normal {
        a { 
            height: auto;
            width: auto;
            line-height: auto;
            padding: 0px;
            border: none;
            background-color: transparent;
            &:hover {
                background-color: transparent;
                color: $primary-five;
            }
        }
    }
}

.new-notification {
    height: 6px;
    width: 6px;
    border-radius: 100%;
    background-color: $primary-two;
    position: absolute;
    right: 0;
}


// Header Two Area Css

.header-two {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    .header-bottom-area {
        height: 110px;
        align-items: center;
        display: flex;
        @media #{$tablet-device,$large-mobile} {
            height: auto;
        }
    }
}
.header-two-right-side {
    margin: 15px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .single-action-item {
        position: relative;
        border-radius: 10px;
        border: 2px solid rgba(255, 255, 255, 0.5);
        background-color: transparent;
        &::after,
        &::before {
            height: 2px;
            width: 6px;
            background-color: #fff;
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s ease-in-out;
        }
        &::after {
            bottom: -2px;
        }
        &::before {
            top: -2px;
        }
        &:hover {
            &::after,
            &::before {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .new-notification {
        height: 6px;
        width: 6px;
        border-radius: 100%;
        background-color: $primary-two;
        position: absolute;
        right: 6px;
        top: 4px;
    }
}

// Header three Area Css

.header-three {
    .header-bottom-area {
        height: 110px;
        align-items: center;
        display: flex;
        @media #{$tablet-device,$large-mobile} {
            height: auto;
        }
    }
    }
.header-three-right-side {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 25px 0;
    .sign-up-action-button {
        font-size: 16px;
        font-weight: 500;
        margin-left: 20px;
        color: $white;
        background-color: $secondary-three;
        white-space: nowrap;
        line-height: 46px;
        border-radius: 20px;
        &:hover {
            background-color: $primary-three;
            color: $white;
        }
        @media #{$small-mobile} {
            padding: 0 10px;
            font-size: 13px;
        }
    } 
    .single-action-item {
        background-color: #efe1ff;
        border-radius: 20px;
        position: relative;
        .new-notification {
            height: 6px;
            width: 6px;
            border-radius: 100%;
            background-color: $primary-two;
            position: absolute;
            right: 8px;
            top: 6px;
        }
        .btn-medium {
            padding: 0 26px;
        }
    }
}

.header-style-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

// Header Four Area Css

.header-four {
    border-bottom: 1px solid rgba($color: #fff, $alpha: 0.3);
}


.header-four-right-side {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 25px 0;
    white-space: nowrap;
    .sign-up-action-button {
        font-size: 15px;
        font-weight: 500;
        margin-left: 20px;
        color: $white;
        background-color: transparent;
        border: 2px solid #fff;
        height: 66px;
        line-height: 64px;
        padding: 0px 20px;
        &:hover {
            background-color: $primary-four;
            color: $white;
            border: 2px solid $primary-four;
        }
        @media #{$large-mobile,$tablet-device} {
            padding: 0 10px;
            font-size: 13px;
            height: 46px;
            line-height: 44px;
        }
    } 
    .single-action-item {
        background-color: transparent;
        border: 2px solid #fff;
        border-radius: 15px;
        position: relative;
        height: 64px;
        line-height: 64px;
        padding: 0px 20px;
        .new-notification {
            height: 6px;
            width: 6px;
            border-radius: 100%;
            background-color: #d83d40;
            position: absolute;
            right: 10px;
            top: 8px;
        }
        @media #{$large-mobile,$tablet-device} {
            height: 46px;
            line-height: 44px;
            padding: 0px 15px;
        }
    }
}



// Header Five Area Css

.header-five {
    .header-top {
        background: url("../images/banners/header-top.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        padding: 15px 0;
    }
    .time-offer {
        color: $white;
        font-weight: 18px;
        .offer-text {
            color: $primary-two;
            margin-right: 15px;
        }
        .get-offer-btn {
            padding: 6px 16px;
            background-color: $secondary-five;
            color: $black;
            border-radius: 10px;
            font-weight: 600;
            &:hover {
                background-color: $primary-two;
            }
        } 
    }
    .social-share-area {
        text-align: right;
        @media #{$large-mobile} {
            text-align: center;
            margin-top: 20px;
        }
        @media #{$tablet-device} {
            li {
                margin-right: 6px;
            }
        }
    }
}

.header-five-left-side-box {
    display: flex;
    align-items: center;
    .ml-3 {
        margin-left: 60px;
        @media #{$tablet-device,$large-mobile} {
            margin-left: 0px;
        }
    }
}

.header-five-right-side {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 25px 0;
    .sign-up-action-button {
        font-size: 16px;
        font-weight: 600;
        margin-left: 30px;
        border-radius: 10px;
        &:hover {
            background-color: $primary-two;
        }
        @media #{$desktop-device} {
            margin-left: 20px;
        }
        @media #{$small-mobile} {
            margin-left: 0px;
            padding: 0 15px;
            font-size: 13px;
        }
    } 
    .log-in-action-btn {
        font-weight: 600;
    }
}



// Header Six Area Css

.header-six {
    .header-top {
        padding: 10px 0;
        border-bottom: 1px solid #e8e8e8;
    }
    .time-offer {
        color: $black;
        font-weight: 18px;
        .get-offer-btn {
            padding: 6px 16px;
            background-color: $secondary-five;
            color: $black;
            border-radius: 10px;
            font-weight: 600;
            margin-left: 20px;
            &:hover {
                background-color: $primary-two;
            }
        } 
    }
    .social-share-area {
        text-align: right;
        @media #{$large-mobile,$tablet-device} {
            text-align: center;
        }
    }

    .header-bottom-area {
        @media #{$large-mobile,$tablet-device} {
            margin-top: 20px;
        }
    }
}

/* Mobile Mavigation icon */

.mobile-navigation-icon{
    width: 24px;
    height: 25px;
    position: relative;
    cursor: pointer;
    display: inline-block;
    margin-right: 0px;
    margin-left: 15px;
    &:hover{
        i{
            background-color: $primary;
            &:before{
                width: 80%;
                background-color: $primary;
            }
            &:after{
                background-color: $primary;
                width: 60%;
            }
        }
    }

    i{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 2px;
        background-color: #333;
        transition: $transition--default;

        &:before{
            position: absolute;
            bottom: 8px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: #333;
            content: "";
            transition: $transition--default;
        }

        &:after{
            position: absolute;
            bottom: -8px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: #333;
            content: "";
            transition: $transition--default;
        }
    }
    &.icon-white {
        i{
            background-color: #fff;
            &:before{
                background-color: #fff;
            }
            &:after{
                background-color: #fff;
            }
        }
        &:hover{
            i{
                background-color: $primary;
                &:before{
                    width: 80%;
                    background-color: $primary;
                }
                &:after{
                    background-color: $primary;
                    width: 60%;
                }
            }
        }
    }
    &.white-md-icon {
        @media #{$desktop-device}{
            i{
                background-color: #fff;
                &:before{
                    background-color: #fff;
                }
                &:after{
                    background-color: #fff;
                }
            }
            &:hover{
                i{
                    background-color: $primary;
                    &:before{
                        width: 80%;
                        background-color: $primary;
                    }
                    &:after{
                        background-color: $primary;
                        width: 60%;
                    }
                }
            }
        }
    }
}






/*================================ 
    - Mobile Menu Overlay Css
==================================*/

.mobile-menu-overlay , .page-oppen-off-sidebar {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $black;
    overflow: auto;
    transition: $transition--default;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.7);
    visibility: hidden;
    opacity: 0;
    
    &__inner{
        transform: translateX(120%);
        width: 400px;
        height: 100%;
        float: right;
        cursor: default;
        background: $primary;
        transition: $transition--default;
        overflow-y: auto;

        @media #{$extra-small-mobile}{
            width: 300px;
        }
    }

    &.active{
        .mobile-menu-overlay__inner{
            transform: translateX(00%);
        }
        visibility: visible;
        opacity: 1;
    }

    &__header{
        background-color: $white;
        padding: 15px 0;
        .mobile-navigation-close-icon{
            position: relative;
            cursor: pointer;
            height: 40px;
            width: 40px;
            line-height: 40px;
            display: inline-block;
            margin-right: auto;
            &:before{
                position: absolute;
                top: 23px;
                left: 8px;
                content: '';
                width: 24px;
                height: 3px;
                background: $black;
                transform-origin: 50% 50%;
                transform: rotate(45deg);
                transition: $transition--default;
            }
            &:after{
                position: absolute;
                top: 23px;
                left: 8px;
                content: '';
                width: 24px;
                height: 3px;
                background: $black;
                transform-origin: 50% 50%;
                transform: rotate(-45deg);
                transition: $transition--default;
            }
            &:hover{
                color: $primary;
                &:before, &:after{
                    transform: none;
                }
            }
        }
    }
    &__body{
        
        padding: 20px 40px 100px;
        .offcanvas-navigation{
            & > ul{
                & > li{
                    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

                    & > a{
                        display: block;
                        color: $black;
                        padding-top: 18px;
                        padding-bottom: 18px;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 1.5;
                        transition: $transition--default;

                        &:hover{
                            color: $black;
                        }
                    }

                    &:last-child{
                        border-bottom: 0;
                    }

                    &.has-children{
                        position: relative;

                        &.active{
                            .menu-expand{
                                &:before{
                                    content: '\eaa1';
                                }
                            }
                        }

                       .menu-expand{
                            position: absolute;
                            right: 0;
                            top: 12px;
                            width: 40px;
                            height: 40px;
                            background: rgba(255,255,255,.1);
                            color: $black;
                            text-align: center;
                            line-height: 40px;
                            cursor: pointer;
                            transition: $transition--default;

                            &:hover{
                                background: rgba(255,255,255,.2);
                            }

                            &:before{
                                content: '\ea99';
                                font-size: 18px;
                                font-family: IcoFont;

                            }
                       }

                        .sub-menu{
                            padding: 12px 0 14px 10px;
                            border-top: 1px solid rgba(255, 255, 255, 0.15);
                            li{
                                border-bottom: 1px solid rgba(255, 255, 255, 0.15);
                                a {
                                    display: block;
                                    font-size: 15px;
                                    color: rgba($black, 0.7);
                                    font-weight: 500;
                                    line-height: 1.5;
                                    padding: 10px 0;

                                    &:hover{
                                        color: $black;;
                                    }
                                }

                                &:last-child{
                                    border-bottom: 0;
                                }
                                &.has-children{
                                    position: relative;
                                    &.active {
                                        .menu-expand{
                                            &:before{
                                                content: "\ea99";
                                        }
                                    }
                                }
        
                                .menu-expand{
                                    position: absolute;
                                    right: 0;
                                    top: 6px;
                                    width: 30px;
                                    height: 30px;
                                    background: rgba(255,255,255,.1);
                                    color: $black;
                                    text-align: center;
                                    line-height: 30px;
                                    cursor: pointer;
                                    transition: $transition--default;
            
                                    &:hover{
                                        background: rgba(255,255,255,.2);
                                    }
                                    &:before{
                                        content: '\ea99';
                                        font-size: 16px;
                                        font-family: IcoFont;
                                        font-weight: 500;
                                    }
                                        }
                                    } 
                                }

                                .sub-menu{
                                    li {
                                        &.has-children{
                                            position: relative;

                                        &.active{
                                            .menu-expand{
                                                &:before{
                                                    content: "\f106";
                                                }
                                            }
                                        }
                                        .menu-expand{
                                            &:before{
                                                content: '\f107';
                                                font-size: 16px;
                                                font-family: IcoFont;
                                                font-weight: 500;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*===============================
    - search overlay
===============================*/


.search-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $black;
    transition: $transition--default;
    z-index: 9999;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    transform: translateY(100%);
    transition: $transition--default;
    
    &__inner{
        width: 100%;
        height: 100%;
        cursor: default;
        background: $white;
        transition: $transition--default;
        overflow-y: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.active{
        transform: translateY(0);
        visibility: visible;
        opacity: 1;
    }

    &__header{
        background-color: $white;
        padding: 15px 0;
        .mobile-navigation-close-icon{
            position: relative;
            cursor: pointer;
            height: 48px;
            width: 48px;
            line-height: 48px;
            display: inline-block;
            &:before{
                position: absolute;
                top: 28px;
                left: 0px;
                content: '';
                width: 42px;
                height: 3px;
                background: $black;
                transform-origin: 50% 50%;
                transform: rotate(45deg);
                transition: $transition--default;
            }
            &:after{
                position: absolute;
                top: 28px;
                left: 0px;
                content: '';
                width: 42px;
                height: 3px;
                background: $black;
                transform-origin: 50% 50%;
                transform: rotate(-45deg);
                transition: $transition--default;
            }
            &:hover{
                color: $primary;
                &:before, &:after{
                    transform: none;
                }
            }
        }
    }
    &__body{
        width: 100%;
        margin: 0 auto;
        margin-bottom: 75px;
    }

    &__form{
        position: relative;
        max-width: 1200px;
        padding: 0 15px;
        width: 100%;
        margin: auto;
        input{
            background-color: transparent;
            border: 0;
            border-bottom: 2px solid  $primary;
            border-radius: 0;
            padding: 15px 50px 15px 0;
            width: 100%;
            color: $white;
            font-size: 42px;
            height: 60px;
            color: $primary;
            @media #{$large-mobile}{
                font-size: 30px;
                height: 60px;
            }

            &::placeholder{
                color: $primary;
                transition: $transition--default;
            }
            &[type="text"]:focus{
                color:  $primary;
                border-color: $primary;
            }
        }
    }
}
