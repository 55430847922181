@charset "UTF-8";
/*============================
    CSS Index         
==============================

    01. Default Css  
        - Scroll top 
    02. Section Spacing Css
    03. Custom Container Css  
    04. Section title Css  
    05. Breadcrumb Css 
    06. Button Css 
    07. Testimonial Css  
    08. Video Popup Css 
    09. Header Css
    10. Navigation Menu Css
    11. Footer Area Css
    12. Hero Area Css
    13. Trending Article Area Css
    14. From Following Area Css
    15. Trending Topic Area Css
    16. Newsletter Area Css
    17. Featured Video Area Css
    18. Latest Post Area Css
    19. Recent Reading Area Css
    20. Archive Search Area Css
    21. Add Banner Area Css
    22. Most Populer Area Css
    23. Recent Article Area Css
    24. Trusted Partners Area Css
    25. Platform Area Css
    26. Team Area Css
    27. Blog Details Area Css
    28. Author Post Area Css
    29. FAQ Area Css
    30. Contact us page Css
    31. Error 404 page Css
    32. Write Content page Css
    33. login page Css
    
/*=====  End of CSS Index  ======*/
/*=============================================
=             01. Default Css                 =
=============================================*/
*, *::after, *::before {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  line-height: 1.74;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  visibility: visible;
  font-family: "Gordita";
  color: #333;
  position: relative;
  background-color: #ffffff;
}

body.no-overflow {
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  color: #000000;
  font-family: "Gordita";
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.41;
}

h1 {
  font-size: 56px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h1 {
    font-size: 46px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 34px;
  }
}

h2 {
  font-size: 38px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h2 {
    font-size: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  h2 {
    font-size: 26px;
  }
}

h3 {
  font-size: 24px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h3 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  h3 {
    font-size: 20px;
  }
}

h4 {
  font-size: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h4 {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h4 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  h4 {
    font-size: 18px;
  }
}

h5 {
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  h5 {
    font-size: 16px;
  }
}

h6 {
  font-size: 16px;
}

p:last-child {
  margin-bottom: 0;
}

a, button {
  color: inherit;
  display: inline-block;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

a, button, img, input {
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

.btn.focus, .btn:focus {
  outline: none;
  box-shadow: none;
}

a:hover {
  text-decoration: none;
  color: #ffc4a0;
}

.theme-color-two a:hover {
  color: #ff7d6b;
}

.theme-color-three a:hover {
  color: #a50eff;
}

.theme-color-four a:hover {
  color: #5974ff;
}

.theme-color-five a:hover {
  color: #5138ee;
}

.theme-color-six a:hover {
  color: #5138ee;
}

button, input[type="submit"] {
  cursor: pointer;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.form-messege.success,
.form-messege-2.success {
  color: green;
}

.form-messege.error,
.form-messege-2.error {
  color: red;
}

.mark, mark {
  padding: 0 0;
  background-color: transparent;
}

.font-weight--bold {
  font-weight: 800;
}

.font-weight--reguler {
  font-weight: 500;
}

.font-weight--normal {
  font-weight: 400;
}

.font-weight--light {
  font-weight: 300;
}

/*--
    Default Class
*/
.text-color-primary {
  color: #ffc4a0;
}

.text-color-secondary {
  color: #f9c322;
}

.text-black {
  color: #000000 !important;
}

.bg-gray {
  background-color: #edf0f8;
}

.bg-gray-1 {
  background-color: #fafafa;
}

.bg-gray-2 {
  background-color: #f8f8f8;
}

.bg-gradient {
  background: -webkit-linear-gradient(top, #FFF 0, #F5F5F5 100%);
}

/*-- Tab Content & Pane Fix --*/
select {
  padding: 3px 20px;
  height: 56px;
  max-width: 100%;
  width: 100%;
  outline: none;
  border: 1px solid #f8f8f8;
  border-radius: 5px;
  background: #f8f8f8 url("../images/icons/selector-icon.png") no-repeat center right 20px;
  background-color: #f8f8f8;
  -moz-appearance: none;
  -webkit-appearance: none;
}

select:focus {
  background: #f8f8f8 url("../images/icons/selector-icon.png") no-repeat center right 20px !important;
}

.fixed-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/*------- Defauld Class --------*/
.text-black {
  color: #333;
}

.box-shadow-top {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
}

.border {
  border: 1px solid #ededed !important;
}

.border-top {
  border-top: 1px solid #ededed !important;
}

.border-right {
  border-right: 1px solid #ededed !important;
}

.border-bottom {
  border-bottom: 1px solid #ededed !important;
}

.border-left {
  border-left: 1px solid #ededed !important;
}

.border-top-dash {
  border-top: 1px dashed #ddd !important;
}

.border-bottom-dash {
  border-bottom: 1px dashed #ddd !important;
}

.border-top-thick {
  border-top: 2px solid #ededed !important;
}

.border-bottom-thick {
  border-bottom: 2px solid #ededed !important;
}

.border-top-drak {
  border-top: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.border-bottom-drak {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}

img {
  max-width: 100%;
}

.img-width {
  width: 100%;
}

::selection {
  color: #ffffff;
  background-color: #ffc4a0;
}

form {
  /* FF 4-18 */
  /* FF 19+ */
  /* IE 10+ */
}

form input:focus::-webkit-input-placeholder {
  color: transparent;
}

form input:focus:-moz-placeholder {
  color: transparent;
}

form input:focus::-moz-placeholder {
  color: transparent;
}

form input:focus:-ms-input-placeholder {
  color: transparent;
}

form input, form textarea {
  font-weight: 500;
}

form input::placeholder, form textarea::placeholder {
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

input[type="text"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="number"]:focus, input[type="tel"]:focus, input[type="range"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="week"]:focus, input[type="time"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="color"]:focus, textarea:focus, select:focus, select:focus, textarea:focus {
  color: #ffc4a0;
  border-color: #ffc4a0;
}

input[type="checkbox"] {
  position: relative;
  background: 0 0;
  border-width: 0;
  box-shadow: none;
  margin: 0 10px 0 3px;
  cursor: pointer;
}

.navigation-button {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  background-color: #f4f4f4;
  font-size: 24px;
  border-radius: 50000px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.navigation-button:hover {
  background-color: #ffc4a0;
  color: #ffffff;
}

.navigation-button:last-child {
  margin-left: 10px;
}

/*=============================================
   - Scroll top         
=============================================*/
.scroll-top {
  position: fixed;
  right: 30px;
  bottom: -60px;
  z-index: 999;
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.03);
  display: block;
  padding: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  font-size: 25px;
  line-height: 60px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  background-color: #ffc4a0;
  background-size: 200% auto;
  background-position: left center;
  color: #ffffff;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow: hidden;
}

@media only screen and (max-width: 479px) {
  .scroll-top {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
}

.scroll-top.show {
  visibility: visible;
  opacity: 1;
  bottom: 60px;
}

.scroll-top i {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #ffffff;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.scroll-top .arrow-top {
  transform: translate(-50%, -50%);
}

.scroll-top .arrow-bottom {
  transform: translate(-50%, 80px);
}

.scroll-top:hover {
  background-position: right center;
}

.scroll-top:hover .arrow-top {
  transform: translate(-50%, -80px);
}

.scroll-top:hover .arrow-bottom {
  transform: translate(-50%, -50%);
}

/*============================================
=           02. Section Spacing Css          =
=============================================*/
.section-space {
  /* Section Padding Css */
  /* Section Margin Css */
}

.section-space--pt_150 {
  padding-top: 150px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-space--pt_150 {
    padding-top: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_150 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_150 {
    padding-top: 60px;
  }
}

.section-space--ptb_120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_120 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_120 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-space--pt_120 {
  padding-top: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_120 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_120 {
    padding-top: 60px;
  }
}

.section-space--pb_120 {
  padding-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_120 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_120 {
    padding-bottom: 60px;
  }
}

.section-space--ptb_100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_100 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-space--pt_100 {
  padding-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_100 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_100 {
    padding-top: 60px;
  }
}

.section-space--pb_100 {
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_100 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_100 {
    padding-bottom: 60px;
  }
}

.section-space--ptb_90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_90 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_90 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.section-space--pt_90 {
  padding-top: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_90 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_90 {
    padding-top: 40px;
  }
}

.section-space--pb_90 {
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_90 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_90 {
    padding-bottom: 40px;
  }
}

.section-space--ptb_80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_80 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_80 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.section-space--pt_80 {
  padding-top: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_80 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_80 {
    padding-top: 40px;
  }
}

.section-space--pb_80 {
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_80 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_80 {
    padding-bottom: 40px;
  }
}

.section-space--ptb_70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_70 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_70 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.section-space--pt_70 {
  padding-top: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_70 {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_70 {
    padding-top: 30px;
  }
}

.section-space--pb_70 {
  padding-bottom: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_70 {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_70 {
    padding-bottom: 30px;
  }
}

.section-space--ptb_60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--ptb_60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-space--pt_60 {
  padding-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_60 {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_60 {
    padding-top: 60px;
  }
}

.section-space--pb_60 {
  padding-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_60 {
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_60 {
    padding-bottom: 60px;
  }
}

.section-space--pt_40 {
  padding-top: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_40 {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pt_40 {
    padding-top: 30px;
  }
}

.section-space--pb_40 {
  padding-bottom: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_40 {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--pb_40 {
    padding-bottom: 30px;
  }
}

.section-space--ptb_30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.section-space--pt_30 {
  padding-top: 30px;
}

.section-space--pb_30 {
  padding-bottom: 30px;
}

.section-space--mt_15 {
  margin-top: 15px;
}

.section-space--mt_20 {
  margin-top: 20px;
}

.section-space--mt_30 {
  margin-top: 30px;
}

.section-space--mt_40 {
  margin-top: 40px;
}

.section-space--mt_50 {
  margin-top: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_50 {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_50 {
    margin-top: 30px;
  }
}

.section-space--mt_60 {
  margin-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_60 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_60 {
    margin-top: 30px;
  }
}

.section-space--mt_70 {
  margin-top: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_70 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_70 {
    margin-top: 30px;
  }
}

.section-space--mt_80 {
  margin-top: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_80 {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_80 {
    margin-top: 30px;
  }
}

.section-space--mt_100 {
  margin-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_100 {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_100 {
    margin-top: 60px;
  }
}

.section-space--mt_120 {
  margin-top: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_120 {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mt_120 {
    margin-top: 60px;
  }
}

.section-space--mb_15 {
  margin-bottom: 15px;
}

.section-space--mb_20 {
  margin-bottom: 20px;
}

.section-space--mb_30 {
  margin-bottom: 30px;
}

.section-space--mb_40 {
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .section-space--mb_40 {
    margin-bottom: 30px;
  }
}

.section-space--mb_50 {
  margin-bottom: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_50 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_50 {
    margin-bottom: 30px;
  }
}

.section-space--mb_60 {
  margin-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_60 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_60 {
    margin-bottom: 30px;
  }
}

.section-space--mb_100 {
  margin-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_100 {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_100 {
    margin-bottom: 60px;
  }
}

.section-space--mb_120 {
  margin-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_120 {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-space--mb_120 {
    margin-bottom: 60px;
  }
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .small-mt__0 {
    margin-top: 0px;
  }
  .small-mt__10 {
    margin-top: 10px;
  }
  .small-mt__20 {
    margin-top: 20px;
  }
  .small-mt__30 {
    margin-top: 30px;
  }
  .small-mt__40 {
    margin-top: 40px;
  }
  .small-mt__50 {
    margin-top: 50px;
  }
  .small-mt__60 {
    margin-top: 60px;
  }
  .small-mb__30 {
    margin-bottom: 30px;
  }
  .small-mb__40 {
    margin-bottom: 40px;
  }
  .small-mb__50 {
    margin-bottom: 50px;
  }
  .small-mb__60 {
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tablet-mt__0 {
    margin-top: 0px;
  }
  .tablet-mt__30 {
    margin-top: 30px;
  }
  .tablet-mt__40 {
    margin-top: 40px;
  }
  .tablet-mt__50 {
    margin-top: 50px;
  }
  .tablet-mt__60 {
    margin-top: 60px;
  }
  .tablet-mb__30 {
    margin-bottom: 30px;
  }
  .tablet-mb__40 {
    margin-bottom: 40px;
  }
  .tablet-mb__50 {
    margin-bottom: 50px;
  }
  .tablet-mb__60 {
    margin-bottom: 60px;
  }
}

/*=====  End of spacing  ======*/
/*============================================
=          03. Custom Container Css          =
=============================================*/
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.container-custom-xl {
  max-width: 1540px;
  width: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .container-custom-xl {
    max-width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .container-custom-xl {
    max-width: 100%;
    width: 100%;
  }
}

.container-custom-150 {
  padding-right: 115px;
  padding-left: 115px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .container-custom-150 {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .container-custom-150 {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container-custom-150 {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .container-custom-150 {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.row--35 {
  margin-left: -35px;
  margin-right: -35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row--35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--35 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--35 > [class*="col"],
.row--35 > [class*="col-"] {
  padding-left: 35px;
  padding-right: 35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--35 > [class*="col"],
  .row--35 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--30 {
  margin-left: -30px;
  margin-right: -30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row--30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--30 {
    margin-left: -12px !important;
    margin-right: -12px !important;
  }
}

.row--30 > [class*="col"],
.row--30 > [class*="col-"] {
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row--30 > [class*="col"],
  .row--30 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--30 > [class*="col"],
  .row--30 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--30 > [class*="col"],
  .row--30 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--30 > [class*="col"],
  .row--30 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--17 {
  margin-left: -17px;
  margin-right: -17px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row--17 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--17 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--17 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--17 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--17 > [class*="col"],
.row--17 > [class*="col-"] {
  padding-left: 17px;
  padding-right: 17px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .row--17 > [class*="col"],
  .row--17 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--17 > [class*="col"],
  .row--17 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--17 > [class*="col"],
  .row--17 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--17 > [class*="col"],
  .row--17 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--10 {
  margin-left: -10px;
  margin-right: -10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--10 {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--10 {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media only screen and (max-width: 767px) {
  .row--10 {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
}

.row--10 > [class*="col"],
.row--10 > [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--10 > [class*="col"],
  .row--10 > [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--10 > [class*="col"],
  .row--10 > [class*="col-"] {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--10 > [class*="col"],
  .row--10 > [class*="col-"] {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

/*============================================
=          04. Section title Css          =
=============================================*/
.section-title-two {
  position: relative;
  margin-bottom: 40px;
}

.section-title-two::after {
  background-color: #e3e3e3;
  content: "";
  left: 0;
  top: 50%;
  position: absolute;
  height: 1px;
  width: 100%;
}

.section-title-two h2 {
  color: #0f034a;
  background: #fff;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.section-title-two h2::after {
  background-color: #fff;
  content: "";
  right: -50px;
  left: auto;
  top: 50%;
  position: absolute;
  height: 90%;
  width: 50px;
  transform: translateY(-50%);
}

.section-title-two h2::before {
  background-color: #fff;
  content: "";
  left: -50px;
  right: auto;
  top: 50%;
  position: absolute;
  height: 90%;
  width: 50px;
  transform: translateY(-50%);
}

.section-title-three .title {
  color: #0f034a;
}

.sub-title-four {
  color: #5974ff;
}

/*============================================
=          05. Breadcrumb Css          =
=============================================*/
.breadcrumb-area {
  background-color: #fafafa;
  padding: 80px 0;
}

.breadcrumb-list {
  padding: 10px 20px;
  background-color: #ffebdf;
  display: inline-block;
  border-radius: 15px;
}

.breadcrumb-list li {
  display: inline-block;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 1.5rem;
  position: relative;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: .1rem;
  color: #222;
  content: '';
  height: 6px;
  width: 6px;
  background: #222;
  border-radius: 5000px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 8px;
}

/*============================================
=          06. Button Css          =
=============================================*/
.btn {
  height: 50px;
  line-height: 48px;
  padding: 0 30px;
  background-color: #f4f4f4;
  font-weight: 500;
  border-radius: 15px;
}

.btn:hover {
  background-color: #ffc4a0;
}

.btn i {
  margin-left: 10px;
  font-size: 22px;
}

.btn-primary {
  background: #ffc4a0;
  font-weight: 500;
  border-radius: 10px;
  color: #000000;
  border: none;
}

.btn-primary:focus {
  outline: none !important;
}

.btn-primary:hover, .btn-primary:focus {
  background: #d4966f;
  color: #000000;
  box-shadow: none;
}

.btn-primary i {
  margin-left: 10px;
  font-size: 22px;
}

.btn-bg-2 {
  background-color: #ff7d6b;
  color: #ffffff;
}

.btn-bg-3 {
  background-color: #a50eff;
  color: #ffffff;
}

.btn-bg-4 {
  background-color: #5974ff;
  color: #ffffff;
}

.btn-bg-5 {
  background-color: #fed74b;
  color: #000000;
}

.btn-bg-5-primary {
  background-color: #5138ee;
  color: #ffffff;
}

.btn-bg-5-primary:hover {
  color: #222;
}

.btn-bg-6 {
  background-color: #5138ee;
  color: #ffffff;
}

.btn-bg-white {
  background-color: #ffffff;
  color: #0f034a;
}

.btn-primary-three {
  padding: 0px 30px;
  background: #f4eaff;
  font-weight: 600;
  border-radius: 10px;
  color: #0f034a;
  border: none;
}

.btn-primary-three:focus {
  outline: none !important;
}

.btn-primary-three:hover, .btn-primary-three:focus {
  background: #a50eff;
  color: #ffffff !important;
  box-shadow: none;
}

.btn-primary-three i {
  margin-left: 15px;
  font-size: 22px;
}

.btn-primary-four {
  padding: 0px 30px;
  background: #edf0f8;
  font-weight: 600;
  border-radius: 10px;
  color: #081131;
  border: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-primary-four {
    padding: 0 20px;
  }
}

.btn-primary-four:focus {
  outline: none !important;
}

.btn-primary-four:hover, .btn-primary-four:focus {
  background: #5974ff;
  color: #ffffff !important;
  box-shadow: none;
}

.btn-primary-four i {
  margin-left: 15px;
  font-size: 22px;
}

.btn-outline-2 {
  border: 2px solid #ddd;
  border-radius: 10px;
  color: #ffffff;
  padding-bottom: 2px;
  color: #ff7d6b;
}

.btn-outline-2:hover {
  color: #ffffff;
  border: 2px solid #ff7d6b;
  background-color: #ff7d6b;
}

.btn-large {
  padding: 0 30px;
  height: 60px;
  line-height: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .btn-large {
    padding: 0 20px;
  }
}

.btn-medium {
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
}

.woocommerce {
  background-color: #fff1dc;
  color: #d59a46;
  border-radius: 15px;
}

.woocommerce:hover {
  background-color: #d59a46;
  color: #ffffff;
}

.wordpress {
  background-color: #e0f9f6;
  color: #3ac8bd;
  border-radius: 15px;
}

.wordpress:hover {
  background-color: #3ac8bd;
  color: #ffffff;
}

.magento {
  background-color: #fbefef;
  color: #b36262;
  border-radius: 15px;
}

.magento:hover {
  background-color: #b36262;
  color: #ffffff;
}

.laravel {
  background-color: #9c8bda;
  color: #fff;
  border-radius: 15px;
}

.laravel:hover {
  background-color: #9c8bda;
  color: #ffffff;
}

.ux-design {
  background-color: #e8f7e0;
  color: #8cad78;
  border-radius: 15px;
}

.ux-design:hover {
  background-color: #8cad78;
  color: #ffffff;
}

.online-tutorial {
  background-color: #e3f0ff;
  color: #7f9cbf;
  border-radius: 15px;
}

.online-tutorial:hover {
  background-color: #7f9cbf;
  color: #ffffff;
}

.marketing {
  background-color: #fdf0e8;
  color: #c49076;
  border-radius: 15px;
}

.marketing:hover {
  background-color: #c49076;
  color: #ffffff;
}

.javaScript {
  background-color: #ffe7da;
  color: #fe8e4b;
  border-radius: 15px;
}

.javaScript:hover {
  background-color: #fe8e4b;
  color: #ffffff;
}

.lifestyle {
  background-color: #e1fae3;
  color: #1f9a39;
  border-radius: 15px;
}

.lifestyle:hover {
  background-color: #1f9a39;
  color: #ffffff;
}

.fashion {
  background-color: #ffebdf;
  border-radius: 15px;
}

.fashion:hover {
  color: #fff;
  background-color: #f3d6c5;
}

.health {
  background-color: #dcf1ff;
  border-radius: 15px;
}

.travel {
  background-color: #ffe6af;
  border-radius: 15px;
}

.business {
  background-color: #e1f3ff;
  border-radius: 15px;
}

.food {
  background-color: #f9e7a0;
  border-radius: 15px;
}

.lifesytle {
  background-color: #ffebde;
  border-radius: 15px;
}

.tech {
  background-color: #ffebdf;
  border-radius: 15px;
}

.tech:hover {
  color: #222;
}

.marketing {
  background-color: #fce7e7;
  border-radius: 15px;
  color: #222;
}

.marketing:hover {
  color: #222;
}

.doctor {
  background-color: #e6f9ed;
  border-radius: 15px;
  color: #222;
}

.doctor:hover {
  color: #222;
}

.health {
  background-color: #eaf6fd;
  border-radius: 15px;
  color: #222;
}

.health:hover {
  color: #222;
}

/*============================================
=          07. Testimonial Css          =
=============================================*/
.single-testimonial-item,
.single-testimonial-item-two {
  padding: 30px 35px 60px;
  background-color: #ffffff;
  border-radius: 15px;
  position: relative;
  margin-top: 40px;
}

.single-testimonial-item::after,
.single-testimonial-item-two::after {
  position: absolute;
  right: 35px;
  bottom: 20px;
  content: '';
  background: url("../../assets/images/icons/quote.png");
  background-repeat: no-repeat;
  height: 40px;
  width: 52px;
}

.single-testimonial-item-two::after {
  background: url("../../assets/images/icons/quote-2.png");
  background-repeat: no-repeat;
}

.single-testimonial-item-two .testimonial-author-info p {
  color: #ffc4a0;
}

.testimonial-post-author {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
}

.testimonial-author-image {
  width: 70px;
  flex: 0 0 auto;
  margin-right: 20px;
}

.testimonial-author-info p {
  margin-top: 10px;
  color: #5974ff;
  font-size: 11px;
}

.testimonial-post-content .testimonial-post-title {
  margin-bottom: 25px;
  line-height: 1.6;
}

.testimonial-post-content p {
  font-size: 14px;
  line-height: 2;
}

.testimonial-slider-navigation,
.testimonial-slider-navigation-two {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.testimonial-slider-navigation .navigation-button,
.testimonial-slider-navigation-two .navigation-button {
  width: 50px;
  height: 50px;
  line-height: 45px;
  border: 2px solid #5974ff;
  text-align: center;
  font-size: 25px;
  border-radius: 10px;
  color: #5974ff;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  margin: 10px;
}

.testimonial-slider-navigation .navigation-button:hover,
.testimonial-slider-navigation-two .navigation-button:hover {
  border: 2px solid #5974ff;
  background-color: #5974ff;
  color: #ffffff;
}

.testimonial-slider-navigation-two .navigation-button {
  width: 50px;
  height: 50px;
  line-height: 45px;
  border: 2px solid #ffc4a0;
  text-align: center;
  font-size: 25px;
  border-radius: 500000px;
  color: #ffffff;
  background-color: #ffc4a0;
  transition: all 0.3s ease-in-out;
  margin: 10px;
}

.testimonial-slider-navigation-two .navigation-button:hover {
  border: 2px solid #222;
  background-color: #222;
  color: #ffffff;
}

/*========================================
 =        08. Video Popup Css            =
==========================================*/
.single-popup-wrap {
  position: relative;
}

.single-popup-wrap img {
  width: 100%;
}

.video-link {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: block;
}

.video-link .ht-popup-video.video-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: rgba(8, 106, 216, 0.8);
}

.video-link .ht-popup-video.video-button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.video-link .ht-popup-video.video-button .video-mark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%, -50%);
  pointer-events: none;
}

.video-link .ht-popup-video.video-button .video-mark .wave-pulse {
  width: 1px;
  height: 0;
  margin: 0 auto;
}

.video-link .ht-popup-video.video-button .video-mark .wave-pulse::after, .video-link .ht-popup-video.video-button .video-mark .wave-pulse::before {
  opacity: 0;
  content: '';
  display: block;
  position: absolute;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  border: 3px solid #ffc4a0;
  animation: zoomBig 3.25s linear infinite;
  animation-delay: 0s;
}

.video-link .ht-popup-video.video-button .video-mark .wave-pulse::before {
  animation-delay: .75s;
}

.video-link .ht-popup-video .video-button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.video-link .ht-popup-video .video-button__one {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-link .ht-popup-video .video-button__one .video-play {
  width: 72px;
  height: 72px;
  background: transparent;
  border: 6px solid #fff;
  border-radius: 50%;
  transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
}

.video-link .ht-popup-video .video-button__one .video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
  margin-left: 1px;
}

.video-link .ht-popup-video .video-button__one .video-play-icon::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  transform: translate(-50%, -50%);
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 17px solid #fff;
}

.video-link .ht-popup-video .video-button__two {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-link .ht-popup-video .video-button__two .video-play {
  width: 78px;
  height: 78px;
  background: #ffc4a0;
  border: 3px solid #fff;
  box-shadow: 0 2px 41px 0 rgba(91, 99, 254, 0.36);
  border-radius: 50%;
  transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.07);
}

.video-link .ht-popup-video .video-button__two .video-play-sm {
  width: 58px;
  height: 58px;
  background: #ffc4a0;
  border: 3px solid #fff;
  box-shadow: 0 2px 41px 0 rgba(91, 99, 254, 0.36);
  border-radius: 50%;
  transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.07);
}

.video-link .ht-popup-video .video-button__two .video-play-sm .video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
  margin-left: 1px;
}

.video-link .ht-popup-video .video-button__two .video-play-sm .video-play-icon::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  transform: translate(-50%, -50%);
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 12px solid #fff;
  border-top-width: 8px;
  border-bottom-width: 9px;
  border-left-width: 15px;
  border-left-color: #ffffff;
}

.video-link .ht-popup-video .video-button__two .video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
  margin-left: 1px;
}

.video-link .ht-popup-video .video-button__two .video-play-icon::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  transform: translate(-50%, -50%);
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 17px solid #fff;
  border-top-width: 12px;
  border-bottom-width: 12px;
  border-left-width: 19px;
  border-left-color: #ffffff;
}

.video-link:hover .video-play,
.video-link:hover .video-play-sm {
  transform: scale3d(1.15, 1.15, 1.15);
}

@keyframes zoomBig {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
    border-width: 3px;
  }
  40% {
    opacity: .5;
    border-width: 2px;
  }
  65% {
    border-width: 1px;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 1px;
  }
}

@keyframes zoomBig {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
    border-width: 3px;
  }
  40% {
    opacity: .5;
    border-width: 2px;
  }
  65% {
    border-width: 1px;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 1px;
  }
}

/*========================================
 =        09. Header Css            =
==========================================*/
.header-sticky.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-animation: .95s ease-in-out 0s normal none 1 running fadeInDown;
  animation: .95s ease-in-out 0s normal none 1 running fadeInDown;
  z-index: 999;
  -webkit-transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.position--absolute {
  position: absolute;
  width: 100%;
  z-index: 5;
}

.mobile-menu-right {
  margin-right: 0px;
  float: right;
}

.header-top-area {
  background-color: #22262a;
  padding-bottom: 10px;
}

.header-top-menu-list {
  margin-top: 10px;
}

.header-top-menu-list li {
  display: inline-block;
  padding-right: 20px;
  margin-right: 15px;
  position: relative;
}

.header-top-menu-list li::after {
  position: absolute;
  content: "";
  right: -0px;
  top: 50%;
  height: 4px;
  width: 4px;
  background: #ffc4a0;
  border-radius: 50000px;
  transform: translateY(-50%);
}

.header-top-menu-list li a {
  color: #ffffff;
}

.header-top-menu-list li a:hover {
  color: #ffc4a0;
}

.header-top-menu-list li:last-child {
  padding-right: 0;
  margin-right: 0;
}

.header-top-menu-list li:last-child::after {
  display: none;
}

@media only screen and (max-width: 575px) {
  .header-top-menu-list {
    text-align: center;
  }
}

.header-top-contact-info {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.header-top-single-contact-item {
  display: flex;
  align-items: center;
  color: #ffffff;
  margin: 0 30px;
}

.header-top-single-contact-item:first-child {
  margin-left: 0;
}

.header-top-single-contact-item:last-child {
  margin-right: 0;
}

.header-top-single-contact-item .text-size-small {
  font-size: 12px;
}

@media only screen and (max-width: 575px) {
  .header-top-single-contact-item {
    margin: 0 5px;
    font-size: 13px;
  }
}

.header-top-contact-icon {
  margin-right: 15px;
}

@media only screen and (max-width: 575px) {
  .header-top-contact-icon {
    margin-right: 5px;
  }
}

.header-top-right-side {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #ffc4a0;
}

.header-top-right-side p {
  margin-bottom: 0;
}

.header-top-right-side .wayder-icon {
  margin: 0 10px;
}

@media only screen and (max-width: 575px) {
  .header-top-right-side {
    justify-content: center;
  }
}

.header-mid-area {
  margin-top: 20px;
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 20px;
}

.header-mid-right-side {
  display: flex;
  justify-content: flex-end;
}

.single-action-item {
  height: 45px;
  min-width: 45px;
  background: #f4f4f4;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 20px;
  border-radius: 5px;
}

.single-action-item:hover {
  background: #ffc4a0;
}

.single-action-item:first-child {
  margin-left: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-action-item {
    height: 40px;
    min-width: 40px;
    margin-left: 12px;
  }
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .single-action-item {
    height: 40px;
    min-width: 40px;
    margin-left: 7px;
  }
}

.header-add-banner {
  position: relative;
}

.header-add-banner a {
  display: block;
}

.header-add-text {
  font-size: 15px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 50px;
  display: inline-block;
}

.header-add-text span {
  display: block;
  font-size: 18px;
  text-align: left;
  margin-top: 3px;
  font-weight: bold;
}

.social-share-area li {
  display: inline-block;
  margin-right: 15px;
}

.social-share-area li:last-child {
  margin-right: 0;
}

.social-share-area li a {
  height: 45px;
  width: 45px;
  line-height: 45px;
  text-align: center;
  background-color: #ddd;
  border-radius: 5px;
}

.social-share-area li a:hover {
  background-color: #ffc4a0;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .social-share-area li {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 575px) {
  .social-share-area li {
    margin-right: 6px;
  }
}

.social-share-area.social-share-border-outline li a {
  background-color: transparent;
  border-radius: 15px;
  border: 1px solid #fff;
  color: #ffffff;
}

.social-share-area.social-share-border-outline li a:hover {
  background: #fff;
  color: #222;
}

.social-share-area.social-share-normal a {
  height: auto;
  width: auto;
  line-height: auto;
  padding: 0px;
  border: none;
  background-color: transparent;
}

.social-share-area.social-share-normal a:hover {
  background-color: transparent;
  color: #5138ee;
}

.new-notification {
  height: 6px;
  width: 6px;
  border-radius: 100%;
  background-color: #ff7d6b;
  position: absolute;
  right: 0;
}

.header-two {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.header-two .header-bottom-area {
  height: 110px;
  align-items: center;
  display: flex;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header-two .header-bottom-area {
    height: auto;
  }
}

.header-two-right-side {
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-two-right-side .single-action-item {
  position: relative;
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  background-color: transparent;
}

.header-two-right-side .single-action-item::after, .header-two-right-side .single-action-item::before {
  height: 2px;
  width: 6px;
  background-color: #fff;
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.header-two-right-side .single-action-item::after {
  bottom: -2px;
}

.header-two-right-side .single-action-item::before {
  top: -2px;
}

.header-two-right-side .single-action-item:hover::after, .header-two-right-side .single-action-item:hover::before {
  opacity: 1;
  visibility: visible;
}

.header-two-right-side .new-notification {
  height: 6px;
  width: 6px;
  border-radius: 100%;
  background-color: #ff7d6b;
  position: absolute;
  right: 6px;
  top: 4px;
}

.header-three .header-bottom-area {
  height: 110px;
  align-items: center;
  display: flex;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header-three .header-bottom-area {
    height: auto;
  }
}

.header-three-right-side {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 25px 0;
}

.header-three-right-side .sign-up-action-button {
  font-size: 16px;
  font-weight: 500;
  margin-left: 20px;
  color: #ffffff;
  background-color: #0f034a;
  white-space: nowrap;
  line-height: 46px;
  border-radius: 20px;
}

.header-three-right-side .sign-up-action-button:hover {
  background-color: #a50eff;
  color: #ffffff;
}

@media only screen and (max-width: 575px) {
  .header-three-right-side .sign-up-action-button {
    padding: 0 10px;
    font-size: 13px;
  }
}

.header-three-right-side .single-action-item {
  background-color: #efe1ff;
  border-radius: 20px;
  position: relative;
}

.header-three-right-side .single-action-item .new-notification {
  height: 6px;
  width: 6px;
  border-radius: 100%;
  background-color: #ff7d6b;
  position: absolute;
  right: 8px;
  top: 6px;
}

.header-three-right-side .single-action-item .btn-medium {
  padding: 0 26px;
}

.header-style-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-four {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.header-four-right-side {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 25px 0;
  white-space: nowrap;
}

.header-four-right-side .sign-up-action-button {
  font-size: 15px;
  font-weight: 500;
  margin-left: 20px;
  color: #ffffff;
  background-color: transparent;
  border: 2px solid #fff;
  height: 66px;
  line-height: 64px;
  padding: 0px 20px;
}

.header-four-right-side .sign-up-action-button:hover {
  background-color: #5974ff;
  color: #ffffff;
  border: 2px solid #5974ff;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .header-four-right-side .sign-up-action-button {
    padding: 0 10px;
    font-size: 13px;
    height: 46px;
    line-height: 44px;
  }
}

.header-four-right-side .single-action-item {
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 15px;
  position: relative;
  height: 64px;
  line-height: 64px;
  padding: 0px 20px;
}

.header-four-right-side .single-action-item .new-notification {
  height: 6px;
  width: 6px;
  border-radius: 100%;
  background-color: #d83d40;
  position: absolute;
  right: 10px;
  top: 8px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .header-four-right-side .single-action-item {
    height: 46px;
    line-height: 44px;
    padding: 0px 15px;
  }
}

.header-five .header-top {
  background: url("../images/banners/header-top.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 15px 0;
}

.header-five .time-offer {
  color: #ffffff;
  font-weight: 18px;
}

.header-five .time-offer .offer-text {
  color: #ff7d6b;
  margin-right: 15px;
}

.header-five .time-offer .get-offer-btn {
  padding: 6px 16px;
  background-color: #fed74b;
  color: #000000;
  border-radius: 10px;
  font-weight: 600;
}

.header-five .time-offer .get-offer-btn:hover {
  background-color: #ff7d6b;
}

.header-five .social-share-area {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .header-five .social-share-area {
    text-align: center;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-five .social-share-area li {
    margin-right: 6px;
  }
}

.header-five-left-side-box {
  display: flex;
  align-items: center;
}

.header-five-left-side-box .ml-3 {
  margin-left: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header-five-left-side-box .ml-3 {
    margin-left: 0px;
  }
}

.header-five-right-side {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 25px 0;
}

.header-five-right-side .sign-up-action-button {
  font-size: 16px;
  font-weight: 600;
  margin-left: 30px;
  border-radius: 10px;
}

.header-five-right-side .sign-up-action-button:hover {
  background-color: #ff7d6b;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-five-right-side .sign-up-action-button {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .header-five-right-side .sign-up-action-button {
    margin-left: 0px;
    padding: 0 15px;
    font-size: 13px;
  }
}

.header-five-right-side .log-in-action-btn {
  font-weight: 600;
}

.header-six .header-top {
  padding: 10px 0;
  border-bottom: 1px solid #e8e8e8;
}

.header-six .time-offer {
  color: #000000;
  font-weight: 18px;
}

.header-six .time-offer .get-offer-btn {
  padding: 6px 16px;
  background-color: #fed74b;
  color: #000000;
  border-radius: 10px;
  font-weight: 600;
  margin-left: 20px;
}

.header-six .time-offer .get-offer-btn:hover {
  background-color: #ff7d6b;
}

.header-six .social-share-area {
  text-align: right;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .header-six .social-share-area {
    text-align: center;
  }
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .header-six .header-bottom-area {
    margin-top: 20px;
  }
}

/* Mobile Mavigation icon */
.mobile-navigation-icon {
  width: 24px;
  height: 25px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  margin-right: 0px;
  margin-left: 15px;
}

.mobile-navigation-icon:hover i {
  background-color: #ffc4a0;
}

.mobile-navigation-icon:hover i:before {
  width: 80%;
  background-color: #ffc4a0;
}

.mobile-navigation-icon:hover i:after {
  background-color: #ffc4a0;
  width: 60%;
}

.mobile-navigation-icon i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 2px;
  background-color: #333;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-navigation-icon i:before {
  position: absolute;
  bottom: 8px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #333;
  content: "";
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-navigation-icon i:after {
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #333;
  content: "";
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-navigation-icon.icon-white i {
  background-color: #fff;
}

.mobile-navigation-icon.icon-white i:before {
  background-color: #fff;
}

.mobile-navigation-icon.icon-white i:after {
  background-color: #fff;
}

.mobile-navigation-icon.icon-white:hover i {
  background-color: #ffc4a0;
}

.mobile-navigation-icon.icon-white:hover i:before {
  width: 80%;
  background-color: #ffc4a0;
}

.mobile-navigation-icon.icon-white:hover i:after {
  background-color: #ffc4a0;
  width: 60%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mobile-navigation-icon.white-md-icon i {
    background-color: #fff;
  }
  .mobile-navigation-icon.white-md-icon i:before {
    background-color: #fff;
  }
  .mobile-navigation-icon.white-md-icon i:after {
    background-color: #fff;
  }
  .mobile-navigation-icon.white-md-icon:hover i {
    background-color: #ffc4a0;
  }
  .mobile-navigation-icon.white-md-icon:hover i:before {
    width: 80%;
    background-color: #ffc4a0;
  }
  .mobile-navigation-icon.white-md-icon:hover i:after {
    background-color: #ffc4a0;
    width: 60%;
  }
}

/*================================ 
    - Mobile Menu Overlay Css
==================================*/
.mobile-menu-overlay, .page-oppen-off-sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  overflow: auto;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
  visibility: hidden;
  opacity: 0;
}

.mobile-menu-overlay__inner, .page-oppen-off-sidebar__inner {
  transform: translateX(120%);
  width: 400px;
  height: 100%;
  float: right;
  cursor: default;
  background: #ffc4a0;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow-y: auto;
}

@media only screen and (max-width: 479px) {
  .mobile-menu-overlay__inner, .page-oppen-off-sidebar__inner {
    width: 300px;
  }
}

.mobile-menu-overlay.active, .page-oppen-off-sidebar.active {
  visibility: visible;
  opacity: 1;
}

.mobile-menu-overlay.active .mobile-menu-overlay__inner, .page-oppen-off-sidebar.active .mobile-menu-overlay__inner {
  transform: translateX(0%);
}

.mobile-menu-overlay__header, .page-oppen-off-sidebar__header {
  background-color: #ffffff;
  padding: 15px 0;
}

.mobile-menu-overlay__header .mobile-navigation-close-icon, .page-oppen-off-sidebar__header .mobile-navigation-close-icon {
  position: relative;
  cursor: pointer;
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: inline-block;
  margin-right: auto;
}

.mobile-menu-overlay__header .mobile-navigation-close-icon:before, .page-oppen-off-sidebar__header .mobile-navigation-close-icon:before {
  position: absolute;
  top: 23px;
  left: 8px;
  content: '';
  width: 24px;
  height: 3px;
  background: #000000;
  transform-origin: 50% 50%;
  transform: rotate(45deg);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-menu-overlay__header .mobile-navigation-close-icon:after, .page-oppen-off-sidebar__header .mobile-navigation-close-icon:after {
  position: absolute;
  top: 23px;
  left: 8px;
  content: '';
  width: 24px;
  height: 3px;
  background: #000000;
  transform-origin: 50% 50%;
  transform: rotate(-45deg);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-menu-overlay__header .mobile-navigation-close-icon:hover, .page-oppen-off-sidebar__header .mobile-navigation-close-icon:hover {
  color: #ffc4a0;
}

.mobile-menu-overlay__header .mobile-navigation-close-icon:hover:before, .mobile-menu-overlay__header .mobile-navigation-close-icon:hover:after, .page-oppen-off-sidebar__header .mobile-navigation-close-icon:hover:before, .page-oppen-off-sidebar__header .mobile-navigation-close-icon:hover:after {
  transform: none;
}

.mobile-menu-overlay__body, .page-oppen-off-sidebar__body {
  padding: 20px 40px 100px;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li > a, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li > a {
  display: block;
  color: #000000;
  padding-top: 18px;
  padding-bottom: 18px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li > a:hover, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li > a:hover {
  color: #000000;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li:last-child, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li:last-child {
  border-bottom: 0;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children {
  position: relative;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children.active .menu-expand:before, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children.active .menu-expand:before {
  content: '\eaa1';
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .menu-expand, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .menu-expand {
  position: absolute;
  right: 0;
  top: 12px;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  color: #000000;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .menu-expand:hover, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .menu-expand:hover {
  background: rgba(255, 255, 255, 0.2);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .menu-expand:before, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .menu-expand:before {
  content: '\ea99';
  font-size: 18px;
  font-family: IcoFont;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu {
  padding: 12px 0 14px 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li a, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li a {
  display: block;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
  line-height: 1.5;
  padding: 10px 0;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li a:hover, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li a:hover {
  color: #000000;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li:last-child, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li:last-child {
  border-bottom: 0;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children {
  position: relative;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children.active .menu-expand:before, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children.active .menu-expand:before {
  content: "\ea99";
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand {
  position: absolute;
  right: 0;
  top: 6px;
  width: 30px;
  height: 30px;
  background: rgba(255, 255, 255, 0.1);
  color: #000000;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand:hover, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand:hover {
  background: rgba(255, 255, 255, 0.2);
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand:before, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand:before {
  content: '\ea99';
  font-size: 16px;
  font-family: IcoFont;
  font-weight: 500;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children {
  position: relative;
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children.active .menu-expand:before, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children.active .menu-expand:before {
  content: "\f106";
}

.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children .menu-expand:before, .page-oppen-off-sidebar__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children .menu-expand:before {
  content: '\f107';
  font-size: 16px;
  font-family: IcoFont;
  font-weight: 500;
}

/*===============================
    - search overlay
===============================*/
.search-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  transform: translateY(100%);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.search-overlay__inner {
  width: 100%;
  height: 100%;
  cursor: default;
  background: #ffffff;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-overlay.active {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.search-overlay__header {
  background-color: #ffffff;
  padding: 15px 0;
}

.search-overlay__header .mobile-navigation-close-icon {
  position: relative;
  cursor: pointer;
  height: 48px;
  width: 48px;
  line-height: 48px;
  display: inline-block;
}

.search-overlay__header .mobile-navigation-close-icon:before {
  position: absolute;
  top: 28px;
  left: 0px;
  content: '';
  width: 42px;
  height: 3px;
  background: #000000;
  transform-origin: 50% 50%;
  transform: rotate(45deg);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.search-overlay__header .mobile-navigation-close-icon:after {
  position: absolute;
  top: 28px;
  left: 0px;
  content: '';
  width: 42px;
  height: 3px;
  background: #000000;
  transform-origin: 50% 50%;
  transform: rotate(-45deg);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.search-overlay__header .mobile-navigation-close-icon:hover {
  color: #ffc4a0;
}

.search-overlay__header .mobile-navigation-close-icon:hover:before, .search-overlay__header .mobile-navigation-close-icon:hover:after {
  transform: none;
}

.search-overlay__body {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 75px;
}

.search-overlay__form {
  position: relative;
  max-width: 1200px;
  padding: 0 15px;
  width: 100%;
  margin: auto;
}

.search-overlay__form input {
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #ffc4a0;
  border-radius: 0;
  padding: 15px 50px 15px 0;
  width: 100%;
  color: #ffffff;
  font-size: 42px;
  height: 60px;
  color: #ffc4a0;
}

@media only screen and (max-width: 767px) {
  .search-overlay__form input {
    font-size: 30px;
    height: 60px;
  }
}

.search-overlay__form input::placeholder {
  color: #ffc4a0;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.search-overlay__form input[type="text"]:focus {
  color: #ffc4a0;
  border-color: #ffc4a0;
}

/*============================== 
    10. Navigation Menu Css
===============================*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navigation-menu {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .navigation-menu {
    display: none;
  }
}

.navigation-menu > ul > li {
  margin: 0 25px;
  position: relative;
  text-align: left;
  display: inline-block;
}

@media only screen and (min-width: 1500px) and (max-width: 1599px) {
  .navigation-menu > ul > li {
    margin: 0 22px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .navigation-menu > ul > li {
    margin: 0 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navigation-menu > ul > li {
    margin: 0 12px;
  }
}

.navigation-menu > ul > li:last-child {
  margin-right: 0;
}

.navigation-menu > ul > li:first-child {
  margin-left: 0;
}

.navigation-menu > ul > li > a {
  display: block;
  color: #000000;
  padding: 30px 2px;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.18;
  transition: all 0.0s ease-in-out;
}

.navigation-menu > ul > li > a span {
  transition: all 0.3s ease-in-out;
}

.navigation-menu > ul > li.has-children > a {
  position: relative;
}

.navigation-menu > ul > li.has-children > a:after {
  position: static;
  margin-left: 5px;
  font-family: IcoFont;
  content: '\ea99';
  font-size: 14px;
  vertical-align: middle;
  transition: all 0.3s ease-in-out;
}

.navigation-menu > ul > li.has-children--multilevel-submenu {
  position: relative;
}

.navigation-menu > ul > li.has-children:hover .megamenu {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.navigation-menu > ul > li.has-children:hover .megamenu--home-variation__item {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.navigation-menu > ul > li.has-children:hover > .submenu {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.navigation-menu > ul > li:hover > a:after, .navigation-menu > ul > li.active > a:after {
  color: #ffc4a0;
  transition: all 0.3s ease-in-out;
}

.navigation-menu > ul > li:hover > a span, .navigation-menu > ul > li.active > a span {
  color: #ffc4a0;
  transition: all 0.3s ease-in-out;
}

.navigation-menu-white > ul > li > a {
  color: #ffffff;
}

.navigation-menu-white > ul > li > a:before {
  content: '';
  width: 0;
  height: 3px;
  bottom: 0;
  position: absolute;
  left: 0;
  background-color: #ffc4a0;
  transition: all 0.3s ease-in-out;
}

.theme-color-two .navigation-menu > ul > li:hover > a:after, .theme-color-two .navigation-menu > ul > li.active > a:after {
  color: #ff7d6b;
}

.theme-color-two .navigation-menu > ul > li:hover > a span, .theme-color-two .navigation-menu > ul > li.active > a span {
  color: #ff7d6b;
}

.theme-color-two .submenu {
  border-bottom: 3px solid #ff7d6b;
}

.theme-color-two .submenu > li.active > a {
  color: #ff7d6b;
}

.theme-color-two .submenu > li a > span:after {
  background-color: #ff7d6b;
}

.theme-color-two .submenu > li > a:hover {
  color: #ff7d6b;
}

.theme-color-two .submenu > li > a:hover > span:after {
  width: 100%;
  left: 0;
  right: auto;
}

.theme-color-two .megamenu--mega > li > ul > li > a:hover {
  color: #ff7d6b;
}

.theme-color-two .megamenu--mega > li > ul > li.active > a {
  color: #ff7d6b;
}

.theme-color-three .navigation-menu > ul > li a {
  color: #250c83;
}

.theme-color-three .navigation-menu > ul > li:hover > a:after, .theme-color-three .navigation-menu > ul > li.active > a:after {
  color: #a50eff;
}

.theme-color-three .navigation-menu > ul > li:hover > a span, .theme-color-three .navigation-menu > ul > li.active > a span {
  color: #a50eff;
}

.theme-color-three .submenu {
  border-bottom: 3px solid #a50eff;
}

.theme-color-three .submenu > li.active > a {
  color: #a50eff;
}

.theme-color-three .submenu > li a > span:after {
  background-color: #a50eff;
}

.theme-color-three .submenu > li > a:hover {
  color: #a50eff;
}

.theme-color-three .submenu > li > a:hover > span:after {
  width: 100%;
  left: 0;
  right: auto;
}

.theme-color-three .megamenu--mega > li > ul > li > a:hover {
  color: #a50eff;
}

.theme-color-three .megamenu--mega > li > ul > li.active > a {
  color: #a50eff;
}

.theme-color-four .navigation-menu > ul > li:hover > a:after, .theme-color-four .navigation-menu > ul > li.active > a:after {
  color: #5974ff;
}

.theme-color-four .navigation-menu > ul > li:hover > a span, .theme-color-four .navigation-menu > ul > li.active > a span {
  color: #5974ff;
}

.theme-color-four .submenu {
  border-bottom: 3px solid #5974ff;
}

.theme-color-four .submenu > li.active > a {
  color: #5974ff;
}

.theme-color-four .submenu > li a > span:after {
  background-color: #5974ff;
}

.theme-color-four .submenu > li > a:hover {
  color: #5974ff;
}

.theme-color-four .submenu > li > a:hover > span:after {
  width: 100%;
  left: 0;
  right: auto;
}

.theme-color-four .megamenu--mega > li > ul > li > a:hover {
  color: #5974ff;
}

.theme-color-four .megamenu--mega > li > ul > li.active > a {
  color: #5974ff;
}

.theme-color-five .navigation-menu > ul > li:hover > a:after, .theme-color-five .navigation-menu > ul > li.active > a:after {
  color: #5138ee;
}

.theme-color-five .navigation-menu > ul > li:hover > a span, .theme-color-five .navigation-menu > ul > li.active > a span {
  color: #5138ee;
}

.theme-color-five .submenu {
  border-bottom: 3px solid #5138ee;
}

.theme-color-five .submenu > li.active > a {
  color: #5138ee;
}

.theme-color-five .submenu > li a > span:after {
  background-color: #5138ee;
}

.theme-color-five .submenu > li > a:hover {
  color: #5138ee;
}

.theme-color-five .submenu > li > a:hover > span:after {
  width: 100%;
  left: 0;
  right: auto;
}

.theme-color-five .megamenu--mega > li > ul > li > a:hover {
  color: #5138ee;
}

.theme-color-five .megamenu--mega > li > ul > li.active > a {
  color: #5138ee;
}

.theme-color-six .navigation-menu > ul > li:hover > a:after, .theme-color-six .navigation-menu > ul > li.active > a:after {
  color: #5138ee;
}

.theme-color-six .navigation-menu > ul > li:hover > a span, .theme-color-six .navigation-menu > ul > li.active > a span {
  color: #5138ee;
}

.theme-color-six .submenu {
  border-bottom: 3px solid #5138ee;
}

.theme-color-six .submenu > li.active > a {
  color: #5138ee;
}

.theme-color-six .submenu > li a > span:after {
  background-color: #5138ee;
}

.theme-color-six .submenu > li > a:hover {
  color: #5138ee;
}

.theme-color-six .submenu > li > a:hover > span:after {
  width: 100%;
  left: 0;
  right: auto;
}

.theme-color-six .megamenu--mega > li > ul > li > a:hover {
  color: #5138ee;
}

.theme-color-six .megamenu--mega > li > ul > li.active > a {
  color: #5138ee;
}

/*===================================
    - Multilevel Submenu Css
====================================*/
.submenu {
  position: absolute;
  top: 100%;
  left: -20px;
  box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
  border-bottom: 3px solid #ffc4a0;
  background-color: #ffffff;
  transform: translateY(50px);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0.2s;
  transition-duration: 0.4s;
  visibility: hidden;
  opacity: 0;
  min-width: 200px;
  padding: 15px 0;
  z-index: 9;
}

.submenu > li {
  position: relative;
}

.submenu > li > a {
  display: block;
  padding: 5px 20px;
  color: #000000;
  font-weight: 500;
  transition: 0s;
}

.submenu > li > a > span {
  position: relative;
}

.submenu > li > a > span:after {
  content: '';
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  background-color: #ffc4a0;
  transition: 0.3s;
}

.submenu > li > a:hover {
  color: #ffc4a0;
}

.submenu > li > a:hover > span:after {
  width: 100%;
  left: 0;
  right: auto;
}

.submenu > li:hover > .submenu {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
  z-index: 9;
}

.submenu > li.active > a {
  color: #ffc4a0;
}

.submenu > li.has-children > a {
  position: relative;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.submenu > li.has-children > a:after {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-family: IcoFont;
  content: '\f105';
  font-size: 14px;
  vertical-align: middle;
  color: #ababab;
}

.submenu > li.has-children > a:hover:after {
  color: #ffc4a0;
}

.submenu > li.has-children.active > a {
  color: #ffc4a0;
}

.submenu .submenu {
  top: 0px;
  left: 100%;
  right: auto;
}

.submenu .submenu.left {
  right: 100%;
  left: auto;
}

.submenu .submenu .submenu {
  top: 0;
  left: 100%;
  right: auto;
}

.submenu .submenu .submenu.left {
  right: 100%;
  left: auto;
}

.submenu .submenu .submenu .submenu {
  top: 0;
  left: 100%;
  right: auto;
}

.submenu .submenu .submenu .submenu.left {
  right: 100%;
  left: auto;
}

.submenu .submenu .submenu .submenu .submenu {
  top: 0;
  left: 100%;
  right: auto;
}

.submenu .submenu .submenu .submenu .submenu.left {
  right: 100%;
  left: auto;
}

/*=========================================
    - Mega Menu Css
===========================================*/
.megamenu {
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
  border-bottom: 3px solid #ffc4a0;
  background-color: #ffffff;
  transform: translateY(50px);
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0.2s;
  transition-duration: 0.4s;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
}

.megamenu--mega {
  min-width: 980px;
  width: 100%;
  padding: 35px 20px 30px;
  display: flex;
  justify-content: space-around;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .megamenu--mega {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .megamenu--mega {
    min-width: 700px;
  }
}

.megamenu--mega > li {
  flex-basis: 22%;
  padding-left: 15px;
  padding-right: 15px;
}

.megamenu--mega > li .page-list-title {
  font-size: 14px;
  margin-bottom: 20px;
  color: #000000;
}

.megamenu--mega > li > ul > li > a {
  padding: 10px 0;
  color: #ababab;
  line-height: 1.2;
  transition: 0.1s;
}

.megamenu--mega > li > ul > li > a:hover {
  color: #ffc4a0;
}

.megamenu--mega > li > ul > li > a:hover span:after {
  width: 100%;
  left: 0;
  right: auto;
}

.megamenu--mega > li > ul > li > a > span {
  position: relative;
}

.megamenu--mega > li > ul > li > a > span:after {
  content: '';
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  background-color: #ffc4a0;
  transition: 0.3s;
}

.megamenu--mega > li > ul > li.active > a {
  color: #ffc4a0;
}

/*=====================
    11. Footer Area Css
=======================*/
.footer-one .footer-top-area {
  background-color: #22262a;
  padding: 60px 0 90px;
}

.footer-one .footer-top-area p {
  color: #ffffff;
}

.footer-one .footer-bottom-area {
  background: #2d3135;
}

.footer-one .footer-bottom-area p {
  color: #ffffff;
}

.footer-widget {
  margin-top: 30px;
  max-width: 280px;
}

@media only screen and (max-width: 767px) {
  .footer-widget {
    max-width: 100%;
  }
}

.footer-logo {
  margin-bottom: 20px;
}

.footer-socail-share {
  margin-top: 30px;
}

.footer-socail-share li {
  display: inline-block;
  margin-right: 15px;
}

.footer-socail-share li:last-child {
  margin-right: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-socail-share li {
    margin-right: 5px;
  }
}

.footer-socail-share li a {
  height: 45px;
  width: 45px;
  line-height: 45px;
  text-align: center;
  background-color: #2d3135;
  border-radius: 5px;
  color: #ffffff;
}

.footer-socail-share li a:hover {
  background-color: #ffc4a0;
  color: #ffffff;
}

.footer-widget-title {
  margin-bottom: 30px;
}

.footer-widget-title .title {
  color: #ffffff;
}

.footer-subscribe-center {
  margin-right: 0;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .footer-subscribe-center {
    margin-left: 0;
  }
}

.footer-subscribe-area {
  margin-right: 0;
  margin-left: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .footer-subscribe-area {
    margin-left: 0;
  }
}

.footer-subscribe-wrap .single-input {
  margin-bottom: 15px;
}

.footer-subscribe-wrap .single-input input {
  width: 100%;
  max-width: 300px;
  border: 1px solid #2d3135;
  border-radius: 10px;
  font-weight: 500;
  padding: 15px 15px;
  background-color: #2d3135;
  color: #ffc4a0;
}

.footer-subscribe-wrap .single-input ::-webkit-input-placeholder {
  /* Edge */
  color: #ffffff;
}

.footer-subscribe-wrap .single-input :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff;
}

.footer-subscribe-wrap .single-input ::placeholder {
  color: #ffffff;
}

.footer-menu-widget {
  display: flex;
  justify-content: space-between;
  margin-right: -10px;
  margin-left: -5px;
  flex-wrap: wrap;
}

.single-footer-menu {
  width: 33.3333%;
  flex: 0 0 auto;
  padding-left: 5px;
  padding-right: 10px;
  margin-top: 30px;
}

@media only screen and (max-width: 575px) {
  .single-footer-menu {
    width: 50%;
  }
}

.single-footer-menu:last-child {
  padding-left: 50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .single-footer-menu:last-child {
    padding-left: 5px;
  }
}

.footer-widget-menu-list li {
  margin-bottom: 10px;
}

.footer-widget-menu-list li:last-child {
  margin-bottom: 0;
}

.footer-widget-menu-list li a {
  color: #fff;
}

.footer-bottom-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-bottom-inner .copy-right-text a {
  color: #ffc4a0;
}

.footer-bottom-inner .button-right-box {
  margin: 10px 0;
}

@media only screen and (max-width: 767px) {
  .footer-bottom-inner {
    flex-direction: column;
    align-items: center;
  }
  .footer-bottom-inner p {
    margin-top: 20px;
  }
}

.footer-bottom-area .copy-right-center {
  text-align: center;
}

.footer-bottom-area .copy-right-text a {
  color: #ffc4a0;
}

.footer-two .footer-top-area {
  background-color: #252c63;
  padding: 90px 0 110px;
}

.footer-two .footer-top-area p {
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-two .footer-top-area {
    padding: 60px 0 90px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-two .footer-top-area {
    padding: 30px 0 60px;
  }
}

.footer-two .footer-bottom-area {
  background: #252c63;
}

.footer-two .footer-bottom-area p {
  color: #ffffff;
}

.footer-two .footer-bottom-area a {
  color: #ff7d6b;
}

.footer-two .footer-socail-share li a {
  background-color: #3b4179;
}

.footer-two .footer-socail-share li a:hover {
  background-color: #ff7d6b;
  color: #ffffff;
}

@media only screen and (max-width: 575px) {
  .footer-two .single-footer-menu {
    width: 100%;
  }
}

.footer-two .footer-subscribe-wrap .single-input input {
  border: 1px solid #353872;
  background-color: #353872;
}

.footer-two .footer-bottom-area {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 20px 0;
}

.footer-three .footer-top-area {
  background-color: #200b70;
}

.footer-three .footer-widget-top {
  padding: 45px 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer-three .footer-widget-top .footer-logo {
  order: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-three .footer-widget-top .footer-logo {
    order: 1;
  }
}

.footer-three .footer-widget-top .info-text-box {
  order: 2;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-three .footer-widget-top .info-text-box {
    width: 100%;
    order: 3;
  }
}

.footer-three .footer-widget-top .info-text-box .sub-title {
  font-size: 12px;
  color: #ff7d6b;
  margin-bottom: 10px;
}

.footer-three .footer-widget-top .info-text-box .title {
  color: #ffffff;
  font-size: 32px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .footer-three .footer-widget-top .info-text-box .title {
    font-size: 22px;
  }
}

.footer-three .footer-widget-top .button-right-box {
  order: 3;
}

.footer-three .footer-widget-top .button-right-box .btn-primary {
  background-color: #218b00;
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-three .footer-widget-top .button-right-box {
    order: 2;
  }
}

.footer-three .footer-mid-area {
  background-color: #250c83;
  padding: 70px 0 100px;
}

.footer-three .footer-mid-area .footer-subscribe-wrap .single-input input {
  border: 1px solid #453095;
  background-color: transparent;
  border-radius: 15px;
}

.footer-three .footer-mid-area .button-box .btn-primary {
  border-radius: 15px;
}

.footer-three .footer-socail-share li a {
  background-color: #3b4179;
}

.footer-three .footer-socail-share li a:hover {
  background-color: #a50eff;
  color: #ffffff;
}

.footer-three .footer-bottom-area {
  padding: 20px 0;
  border-top: 1px solid #3b2590;
  background-color: #250c83;
}

.footer-three .footer-bottom-area p {
  color: #ffffff;
}

.footer-three .footer-bottom-area a {
  color: #ff7d6b;
}

.footer-four .footer-top-area {
  background-color: #5974ff;
}

.footer-four .footer-widget-top {
  padding: 45px 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer-four .footer-widget-top .info-text-box {
  order: 2;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-four .footer-widget-top .info-text-box {
    width: 100%;
    order: 3;
  }
}

.footer-four .footer-widget-top .info-text-box .sub-title {
  font-size: 12px;
  color: #ffffff;
  margin-bottom: 10px;
}

.footer-four .footer-widget-top .info-text-box .title {
  color: #ffffff;
  font-size: 32px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .footer-four .footer-widget-top .info-text-box .title {
    font-size: 22px;
  }
}

.footer-four .footer-widget-top .button-right-box {
  order: 3;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-four .footer-widget-top .button-right-box {
    order: 2;
  }
}

.footer-four .footer-widget-top .button-right-box a {
  color: #222;
}

.footer-four .footer-item-space {
  padding: 100px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-four .footer-item-space {
    padding: 80px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-four .footer-item-space {
    padding: 30px 0;
  }
}

@media only screen and (max-width: 767px) {
  .footer-four .footer-item-space {
    padding: 20px 0;
  }
}

.footer-four .footer-widget p {
  color: #ffffff;
}

.footer-four .footer-mid-area {
  background-color: #081131;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-four .footer-mid-area {
    padding: 50px 0;
  }
}

@media only screen and (max-width: 767px) {
  .footer-four .footer-mid-area {
    padding: 40px 0;
  }
}

.footer-four .footer-mid-area .footer-widget {
  margin-top: 0;
}

.footer-four .footer-mid-area .footer-border {
  position: relative;
}

.footer-four .footer-mid-area .footer-border::before {
  position: absolute;
  content: '';
  left: -70px;
  top: 0;
  height: 100%;
  width: 1px;
  background-color: #171f3d;
}

.footer-four .footer-mid-area .footer-border:last-child::after {
  position: absolute;
  content: '';
  right: -70px;
  top: 0;
  height: 100%;
  width: 1px;
  background-color: #171f3d;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .footer-four .footer-mid-area .footer-border::before {
    left: -10px;
  }
  .footer-four .footer-mid-area .footer-border:last-child::after {
    right: -10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-four .footer-mid-area .footer-border::before {
    display: none;
  }
  .footer-four .footer-mid-area .footer-border:last-child::after {
    display: none;
    right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-four .footer-mid-area .footer-border:last-child::after {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .footer-four .footer-mid-area .footer-border::before {
    display: none;
  }
  .footer-four .footer-mid-area .footer-border:last-child::after {
    display: none;
  }
}

.footer-four .footer-socail-share li a {
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 15px;
  line-height: 38px;
}

.footer-four .footer-socail-share li a:hover {
  border: 2px solid #5974ff;
  background-color: #5974ff;
  color: #ffffff;
}

.footer-four .footer-bottom-area {
  padding: 20px 0;
  background-color: #081131;
}

.footer-four .footer-bottom-area p {
  color: #ffffff;
}

.footer-four .footer-bottom-area a {
  color: #5974ff;
}

.footer-five .footer-top-area {
  padding: 70px 0 100px;
  background-color: #5138ee;
  background-image: url("../images/bg/footer-bg-five.jpg");
  background-size: cover;
}

@media only screen and (max-width: 767px) {
  .footer-five .footer-top-area {
    padding: 30px 0 60px;
  }
}

.footer-five .footer-subscribe-wrap .single-input input {
  border: 2px solid #fff;
  padding: 15px 30px;
  background-color: transparent;
  border-radius: 15px;
}

.footer-five .footer-dec-text {
  color: #ffffff;
}

.footer-five .footer-socail-share li a {
  background-color: transparent;
  border: 1px solid #fff;
}

.footer-five .footer-socail-share li a:hover {
  border: 1px solid #ffffff;
  background-color: #ffffff;
  color: #5138ee;
}

.footer-five .footer-bottom-area {
  background-color: #462fd7;
  padding: 25px 50px 5px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1499px) {
  .footer-five .footer-bottom-area {
    padding: 25px 0px 5px;
  }
}

.footer-five .copy-right-text {
  color: #fff;
}

.footer-five .copy-right-text a {
  color: #fed74b;
}

.footer-bottom-menu-list li {
  display: inline-block;
  padding-right: 20px;
  margin-right: 20px;
  position: relative;
}

.footer-bottom-menu-list li::after {
  position: absolute;
  content: "";
  right: -0px;
  top: 50%;
  height: 4px;
  width: 4px;
  background: #ffc4a0;
  border-radius: 50000px;
  transform: translateY(-50%);
}

.footer-bottom-menu-list li a {
  color: #ffffff;
}

.footer-bottom-menu-list li a:hover {
  color: #ffc4a0;
}

.footer-bottom-menu-list li:last-child {
  padding-right: 0;
  margin-right: 0;
}

.footer-bottom-menu-list li:last-child::after {
  display: none;
}

.scroll-button-buttom .text {
  color: #fff;
  margin-right: 10px;
}

.scroll-button-buttom .right-side-scroll-up {
  height: 50px;
  width: 50px;
  line-height: 50px;
  color: #222;
  background-color: #fed74b;
  border-radius: 10px;
  text-align: center;
  font-size: 20px;
}

.footer-six .footer-top-area {
  background-color: #081b3c;
  padding: 80px 0 80px;
}

.footer-six .footer-top-area .footer-newsletter-subscribe {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-six .footer-top-area .footer-newsletter-subscribe .section-title {
  max-width: 500px;
}

.footer-six .footer-top-area .footer-newsletter-subscribe .section-title .title {
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .footer-six .footer-top-area .footer-newsletter-subscribe {
    flex-direction: column;
  }
  .footer-six .footer-top-area .footer-newsletter-subscribe .section-title {
    max-width: 100%;
    margin-bottom: 30px;
  }
}

.footer-six .footer-top-area .newsletter-input-box {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
}

.footer-six .footer-top-area .newsletter-input-box .newsletter-input {
  max-width: 450px;
  width: 100%;
  border: 2px solid #ddd;
  border-radius: 10px;
  margin-right: 20px;
  padding: 5px 30px;
  font-weight: 500;
  height: 60px;
  background-color: transparent;
}

.footer-six .footer-top-area .newsletter-input-box .newsletter-input::placeholder {
  color: #ffffff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-six .footer-top-area .newsletter-input-box .newsletter-input {
    max-width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-six .footer-top-area .newsletter-input-box .newsletter-input {
    max-width: 100%;
    margin-bottom: 20px;
  }
}

.footer-six .footer-top-area .newsletter-input-box .newsletter-input ::placeholder {
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .footer-six .footer-top-area .newsletter-input-box {
    flex-direction: column;
  }
}

.footer-six .footer-mid-area {
  background-color: #0a1e43;
  padding: 90px 0 120px;
}

.footer-six .footer-mid-area .dec-text {
  color: #ffffff;
}

.footer-six .footer-socail-share li a {
  background-color: #5974ff;
}

.footer-six .footer-socail-share li a:hover {
  background-color: #5974ff;
  color: #ffffff;
}

.footer-six .footer-bottom-area {
  background: #0a1e43;
  border-top: 1px solid #1d3052;
  padding: 20px 0;
}

.footer-six .footer-bottom-area p {
  color: #ffffff;
}

.footer-six .footer-bottom-area a {
  color: #ffffff;
}

.footer-six .footer-bottom-area .right-side-scroll-up {
  background-color: #5974ff;
}

@media only screen and (max-width: 767px) {
  .footer-six .footer-bottom-area {
    text-align: center;
  }
  .footer-six .footer-bottom-area .scroll-button-buttom {
    margin-top: 10px;
  }
}

/*=====================
    12. Hero Area Css
=======================*/
.hero-area {
  background-color: #f7f7f7;
  padding: 80px 0;
}

.hero-inner-area {
  display: flex;
  justify-content: space-between;
  margin-right: -15px;
  margin-left: -15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .hero-inner-area {
    flex-wrap: wrap;
  }
}

.hero-category-area {
  max-width: 290px;
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-category-area {
    width: 35%;
  }
}

@media only screen and (max-width: 767px) {
  .hero-category-area {
    width: 35%;
  }
}

@media only screen and (max-width: 575px) {
  .hero-category-area {
    width: 100%;
    max-width: 100%;
  }
}

.single-hero-category-item {
  display: block;
  margin-bottom: 28px;
  position: relative;
}

.single-hero-category-item:last-child {
  margin-bottom: 0;
}

.single-hero-category-item img {
  border-radius: 15px;
  width: 100%;
}

.single-hero-category-item::after {
  position: absolute;
  height: 100%;
  width: 100%;
  content: "";
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 15px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.single-hero-category-item:hover::after {
  opacity: 1;
  visibility: visible;
}

.single-hero-category-item:hover .hero-category-inner-box {
  opacity: 1;
  visibility: visible;
}

.hero-category-inner-box {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 30px;
  color: #fff;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-category-inner-box {
    padding: 5px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-category-inner-box {
    padding: 5px 10px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-category-inner-box {
    padding: 10px 30px;
  }
}

.hero-category-inner-box .title {
  color: #ffffff;
}

.hero-category-inner-box .icon {
  font-size: 28px;
  height: 40px;
  width: 40px;
  background-color: #ffc4a0;
  line-height: 40px;
  text-align: center;
  border-radius: 50000px;
}

.hero-banner-area {
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-banner-area {
    width: 64%;
  }
}

@media only screen and (max-width: 767px) {
  .hero-banner-area {
    width: 64%;
  }
}

@media only screen and (max-width: 575px) {
  .hero-banner-area {
    margin-top: 45px;
    width: 100%;
    max-width: 100%;
  }
}

.hero-banner-area a {
  display: block;
}

.hero-banner-area a img {
  border-radius: 15px;
  width: 100%;
}

.hero-blog-post {
  max-width: 375px;
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px), only screen and (max-width: 575px) {
  .hero-blog-post {
    max-width: 100%;
    width: 100%;
    margin-top: 45px;
  }
}

.single-hero-blog-post {
  margin-top: 45px;
}

.single-hero-blog-post:first-child {
  margin-top: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-blog-post {
    margin-top: 25px;
  }
}

.hero-blog-post-top {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-blog-post-top {
    margin-bottom: 10px;
  }
}

.hero-blog-post-category {
  margin-right: 25px;
}

.hero-blog-post-category a {
  min-width: 100px;
  background: #ffebdf;
  text-align: center;
  padding: 6px 5px;
  border-radius: 10px;
}

.hero-blog-post-author {
  color: #9b9ea1;
}

.hero-blog-post-author a {
  color: #000000;
}

.hero-blog-post-title {
  margin-bottom: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-blog-post-title {
    margin-bottom: 10px;
  }
}

.hero-blog-post-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.post-meta-left-side span {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
  font-size: 13px;
}

.post-meta-left-side span::after {
  position: absolute;
  content: "";
  right: -0px;
  top: 50%;
  height: 4px;
  width: 4px;
  background: #000000;
  border-radius: 50000px;
  transform: translateY(-50%);
}

.post-meta-left-side span a:hover {
  color: #ffc4a0;
}

.post-meta-left-side span:last-child {
  padding-right: 0;
  margin-right: 0;
}

.post-meta-left-side span:last-child::after {
  display: none;
}

.post-meta-right-side a {
  margin-left: 10px;
}

.hero-area-two-wrapper {
  position: relative;
}

.hero-area-two-wrapper .hero-two-banner-text {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
}

.hero-area-two {
  background: url("../images/hero/home-2-hero-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 850px;
  display: flex;
  align-items: center;
  background-color: #091d40;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-area-two {
    height: 750px;
  }
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area-two {
    height: auto;
    padding: 150px 0 100px;
  }
}

.hero-area-overly {
  position: relative;
}

.hero-area-overly::before {
  content: '';
  background-color: rgba(15, 0, 84, 0.7);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.hero-area--two-innter {
  position: relative;
}

.hero-area--two-innter .sub-title {
  color: #ff7d6b;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.hero-area--two-innter .hero-title {
  color: #ffffff;
  font-size: 95px;
  font-weight: bold;
}

.hero-area--two-innter .hero-title-small {
  font-size: 60px;
  color: #ffffff;
  font-weight: bold;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-area--two-innter .hero-title {
    font-size: 75px;
  }
  .hero-area--two-innter .hero-title-small {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area--two-innter .hero-title {
    font-size: 65px;
  }
  .hero-area--two-innter .hero-title-small {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-area--two-innter .hero-title {
    font-size: 45px;
  }
  .hero-area--two-innter .hero-title-small {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-area--two-innter .hero-title {
    font-size: 45px;
  }
  .hero-area--two-innter .hero-title-small {
    font-size: 20px;
  }
}

.hero-two-tag {
  max-width: 750px;
  margin: 60px auto 0;
}

.hero-two-tag a {
  margin: 10px;
  padding: 0 35px;
  height: 50px;
  line-height: 46px;
  border: 2px solid #fff;
}

.hero-two-tag a:hover {
  color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-two-tag {
    margin: 40px auto 0;
  }
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-two-tag {
    margin: 30px auto 0;
  }
}

@media only screen and (max-width: 575px) {
  .hero-two-tag a {
    margin: 5px;
  }
}

.hero-area-three {
  background: url("../images/hero/home-3-hero-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 830px;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #091d40;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area-three {
    height: 600px;
  }
}

.hero-area-three-post .title {
  color: #ffffff;
  font-size: 45px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-area-three-post .title {
    font-size: 36px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area-three-post .title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-area-three-post .title {
    font-size: 28px;
  }
}

.hero-area-three-post .dec {
  color: #ffffff;
  max-width: 470px;
  font-size: 18px;
}

.hero-area-three-post-author {
  color: #9b9ea1;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 18px;
}

.hero-area-three-post-author a {
  color: #fff;
}

.hero-area-three-post-meta {
  margin-top: 25px;
}

.hero-area-three-post-meta > span {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
  font-size: 17px;
  color: #ffffff;
}

.hero-area-three-post-meta > span::after {
  position: absolute;
  content: "";
  right: -0px;
  top: 50%;
  height: 4px;
  width: 4px;
  background: #ffffff;
  border-radius: 50000px;
  transform: translateY(-50%);
}

.hero-area-three-post-meta > span:last-child {
  padding-right: 0;
  margin-right: 0;
}

.hero-area-three-post-meta > span:last-child::after {
  display: none;
}

.hero-three-box {
  margin-right: -60px;
  margin-left: 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .hero-three-box {
    margin-right: 0px;
    margin-left: 0px;
  }
}

.hero-three-inner-image {
  margin-bottom: 20px;
  border-radius: 20px;
  border: 1px solid #ddd;
  padding: 15px;
}

.hero-three-inner-image img {
  border-radius: 20px;
}

.hero-swiper-pagination {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
}

.hero-swiper-pagination .swiper-pagination-bullet {
  height: 16px;
  width: 16px;
  border-radius: 100%;
  background-color: #8b61b7;
  margin: 5px;
}

.hero-swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #fff;
}

.hero-three-category {
  text-align: center;
}

.hero-three-category a {
  margin: 15px;
  min-width: 200px;
  text-align: center;
  height: 66px;
  line-height: 66px;
  border-radius: 15px;
  background: #f4eaff;
}

.hero-three-category a:hover {
  background: #a50eff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-three-category a {
    min-width: 140px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-three-category a {
    min-width: auto;
    margin: 10px 2px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-three-category a {
    min-width: auto;
    margin: 10px 2px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-three-category a {
    min-width: auto;
    margin: 10px 2px;
  }
}

.hero-three-category .category-step-2 {
  width: 90%;
  margin: auto;
}

.hero-area-four {
  background: url("../images/hero/home-4-hero-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #091d40;
  height: 820px;
  padding-top: 150px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-area-four {
    height: 700px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area-four {
    height: 650px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-area-four {
    height: 600px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-area-four {
    height: 600px;
  }
}

@media only screen and (max-width: 479px) {
  .hero-area-four {
    height: 700px;
  }
}

.hero-four-image {
  margin-top: -380px;
  z-index: 1;
  position: relative;
  text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-four-image {
    margin-top: -220px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-four-image {
    margin-top: -220px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-four-image {
    margin-top: -200px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-four-image {
    margin-top: -150px;
  }
}

@media only screen and (max-width: 479px) {
  .hero-four-image {
    margin-top: -150px;
  }
}

.hero-four-inner-image {
  padding: 15px;
  border: 2px solid #edf0f8;
  max-width: 770px;
  border-radius: 15px;
  margin: auto;
}

.hero-four-inner-image img {
  border-radius: 15px;
}

.hero-area-four-post {
  margin-bottom: 40px;
}

.hero-area-four-post .title {
  color: #ffffff;
  font-size: 52px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-area-four-post .title {
    font-size: 46px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area-four-post .title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-area-four-post .title {
    font-size: 28px;
  }
}

.hero-area-four-post .dec {
  color: #ffffff;
}

.hero-area-three-post-author {
  color: #9b9ea1;
  margin-bottom: 10px;
  font-weight: 600;
}

.hero-area-three-post-author a {
  color: #fff;
}

.hero-area-four-post-meta {
  margin-top: 20px;
}

.hero-area-four-post-meta > span {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
  font-size: 13px;
  color: #5974ff;
}

.hero-area-four-post-meta > span::after {
  position: absolute;
  content: "";
  right: -0px;
  top: 50%;
  height: 4px;
  width: 4px;
  background: #5974ff;
  border-radius: 50000px;
  transform: translateY(-50%);
}

.hero-area-four-post-meta > span a {
  color: #ffffff;
}

.hero-area-four-post-meta > span.time {
  color: #ffffff;
}

.hero-area-four-post-meta > span:last-child {
  padding-right: 0;
  margin-right: 0;
}

.hero-area-four-post-meta > span:last-child::after {
  display: none;
}

.slider-four-slider-navigation .navigation-button {
  width: 50px;
  height: 50px;
  line-height: 45px;
  border: 1px solid #e7e5ed;
  text-align: center;
  font-size: 25px;
  border-radius: 10px;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
  position: absolute;
  background-color: transparent;
  left: 100px;
  right: auto;
}

.slider-four-slider-navigation .navigation-button.slider-four-button-prev {
  right: 100px;
  left: auto;
}

.slider-four-slider-navigation .navigation-button:hover {
  border: 1px solid #363449;
  background-color: #363449;
}

.hero-four-category {
  border-top: 1px solid #edf0f8;
  border-bottom: 1px solid #edf0f8;
  padding: 15px 0;
}

.hero-four-category .category-step-1 {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin-left: -15px;
  margin-right: -15px;
}

@media only screen and (max-width: 767px) {
  .hero-four-category .category-step-1 {
    justify-content: start;
  }
}

.hero-four-category a {
  margin: 15px;
  min-width: 200px;
  text-align: center;
  border-radius: 15px;
}

.hero-four-category a:hover {
  color: #ffffff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-four-category a {
    min-width: 140px;
    margin: 10px 2px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-four-category a {
    min-width: auto;
    margin: 10px 2px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-four-category a {
    min-width: auto;
    margin: 10px 10px;
    padding: 0 18px;
  }
}

.hero-four-category .category-step-2 {
  width: 90%;
  margin: auto;
}

.hero-area-five {
  padding: 120px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-area-five {
    padding: 100px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area-five {
    padding: 80px 0;
  }
}

@media only screen and (max-width: 767px) {
  .hero-area-five {
    padding: 60px 0;
  }
}

.hero-five-text .sub-title {
  color: #ff7d6b;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.hero-five-text .title {
  display: block;
  font-size: 60px;
  font-weight: 700;
}

.hero-five-text .title .hero-five-title {
  font-size: 90px;
  display: inline-block;
  position: relative;
  line-height: 1;
}

.hero-five-text .title .hero-five-title::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 2px;
  height: 25px;
  width: 100%;
  background-color: #fed74b;
  z-index: -1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-five-text .title .hero-five-title {
    font-size: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-five-text .title .hero-five-title {
    font-size: 40px;
  }
  .hero-five-text .title .hero-five-title::after {
    height: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-five-text .title .hero-five-title {
    font-size: 34px;
  }
  .hero-five-text .title .hero-five-title::after {
    height: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-five-text .title {
    font-size: 46px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-five-text .title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-five-text .title {
    font-size: 34px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-five-text .title {
    font-size: 30px;
  }
}

.hero-five-text .hero-text-dec {
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 600;
  max-width: 540px;
}

.hero-five-text .button-box .btn-bg-5 {
  background-color: #5138ee;
  color: #ffffff;
}

.hero-five-text .button-box .btn-bg-5:hover {
  color: #fff;
}

.hero-five-category {
  margin-right: -30px;
}

.hero-five-category a {
  margin-right: 25px;
  margin-top: 25px;
}

.hero-five-category a:hover {
  color: #ffffff;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .hero-five-category a {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-five-category a {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-five-category a {
    margin-right: 5px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .hero-five-category {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-five-category {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-five-category {
    margin-right: 0px;
  }
}

.hero-six-area {
  padding: 60px 0 100px;
}

.hero-slide-six-image {
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slide-six-image img {
    width: 100%;
  }
}

.hero-slide-post-content {
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slide-post-content {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slide-post-content {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slide-post-content {
    margin-left: 0px;
  }
}

.hero-slide-post-author {
  color: #9b9ea1;
}

.hero-slide-post-author a {
  color: #091d40;
}

@media only screen and (max-width: 479px) {
  .hero-slide-post-author {
    font-size: 12px;
  }
}

.hero-slide-post-title {
  margin-bottom: 25px;
  font-size: 32px;
  font-weight: 800;
  color: #0f034a;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slide-post-title {
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slide-post-title {
    font-size: 22px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .hero-slide-post-title {
    font-size: 22px;
  }
}

.hero-slide-post-meta {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 479px) {
  .hero-slide-post-meta {
    flex-wrap: wrap;
  }
}

.hero-slide-post-meta span {
  position: relative;
  padding-right: 15px;
  margin-right: 15px;
  font-size: 13px;
  font-weight: 600;
}

.hero-slide-post-meta span::after {
  position: absolute;
  content: "";
  right: -5px;
  top: 50%;
  height: 4px;
  width: 4px;
  background: #091d40;
  border-radius: 50000px;
  transform: translateY(-50%);
}

.hero-slide-post-meta span a:hover {
  color: #5974ff;
}

.hero-slide-post-meta span:last-child {
  padding-right: 0;
  margin-right: 0;
}

.hero-slide-post-meta span:last-child::after {
  display: none;
}

.hero-read-more-button a {
  border-bottom: 1px solid #ddd;
  display: inline-block;
  font-weight: 600;
  color: #0f034a;
}

.hero-read-more-button a i {
  font-size: 18px;
  margin-left: 10px;
}

.slider-six-slider-navigation .navigation-button {
  width: 50px;
  height: 50px;
  line-height: 45px;
  border: 2px solid #e6e8ec;
  text-align: center;
  font-size: 25px;
  border-radius: 10px;
  color: #333;
  transition: all 0.3s ease-in-out;
  left: 100px;
  right: auto;
  position: absolute;
  top: 50%;
  z-index: 1;
  background-color: transparent;
}

.slider-six-slider-navigation .navigation-button.slider-six-button-prev {
  right: 100px;
  left: auto;
}

.slider-six-slider-navigation .navigation-button:hover {
  border: 1px solid #5138ee;
  background-color: #5138ee;
  color: #ffffff;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .slider-six-slider-navigation .navigation-button {
    left: 20px;
  }
  .slider-six-slider-navigation .navigation-button.slider-six-button-prev {
    right: 20px;
  }
}

/*==================================
    13. Trending Article Area Css
====================================*/
.trending-article-row {
  display: flex;
  justify-content: space-between;
  margin-right: -15px;
  margin-left: -15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .trending-article-row {
    flex-wrap: wrap;
  }
}

.trending-article-left-side {
  max-width: 515px;
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .trending-article-left-side {
    max-width: 100%;
  }
}

.trending-article-right-side {
  max-width: 675px;
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .trending-article-right-side {
    margin-top: 30px;
  }
}

.trending-single-item {
  display: flex;
  margin-top: 35px;
}

.trending-single-item:first-child {
  margin-top: 0;
}

@media only screen and (max-width: 767px) {
  .trending-single-item {
    margin-top: 20px;
  }
}

.trending-single-item .trending-post-thum {
  max-width: 160px;
  flex: 0 0 auto;
}

@media only screen and (max-width: 479px) {
  .trending-single-item .trending-post-thum {
    max-width: 100px;
  }
}

.trending-single-item .trending-post-thum img {
  height: 100%;
  vertical-align: middle;
  object-fit: cover;
  border-radius: 10px;
}

.trending-single-item .trending-post-content {
  margin-left: 30px;
}

@media only screen and (max-width: 479px) {
  .trending-single-item .trending-post-content {
    margin-left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .trending-single-item .trending-post-content {
    margin-left: 15px;
  }
}

.trending-blog-post-top {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

@media only screen and (max-width: 479px) {
  .trending-blog-post-top {
    margin-bottom: 10px;
  }
}

.trending-blog-post-category {
  margin-right: 25px;
}

.trending-blog-post-category a {
  min-width: 100px;
  text-align: center;
  padding: 4px 5px;
  border-radius: 10px;
  color: #222;
}

@media only screen and (max-width: 479px) {
  .trending-blog-post-category {
    margin-right: 15px;
  }
  .trending-blog-post-category a {
    min-width: 40px;
    font-size: 12px;
    padding: 2px 5px;
  }
}

.trending-blog-post-author {
  color: #9b9ea1;
}

.trending-blog-post-author a {
  color: #000000;
}

@media only screen and (max-width: 479px) {
  .trending-blog-post-author {
    font-size: 12px;
  }
}

.trending-blog-post-title {
  margin-bottom: 15px;
}

.trending-blog-post-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trending-meta-left-side span {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
  font-size: 13px;
}

.trending-meta-left-side span::after {
  position: absolute;
  content: "";
  right: -0px;
  top: 50%;
  height: 4px;
  width: 4px;
  background: #000000;
  border-radius: 50000px;
  transform: translateY(-50%);
}

.trending-meta-left-side span a:hover {
  color: #ffc4a0;
}

.trending-meta-left-side span:last-child {
  padding-right: 0;
  margin-right: 0;
}

.trending-meta-left-side span:last-child::after {
  display: none;
}

.post-meta-right-side a {
  margin-left: 10px;
}

.large-banner-trending-article {
  display: flex;
}

.large-banner-trending-article .trending-single-item {
  margin-top: 45px;
}

.large-banner-trending-article .trending-single-item:first-child {
  margin-top: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .large-banner-trending-article .trending-single-item {
    margin-top: 25px;
  }
  .large-banner-trending-article .trending-single-item:first-child {
    margin-top: 5px;
  }
}

@media only screen and (max-width: 479px) {
  .large-banner-trending-article .trending-single-item {
    margin-top: 15px;
  }
}

.trending-large-post-thum {
  max-width: 315px;
  flex: 0 0 auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 767px) {
  .trending-large-post-thum {
    max-width: 160px;
  }
}

@media only screen and (max-width: 479px) {
  .trending-large-post-thum {
    max-width: 100px;
  }
}

.trending-large-post-thum img {
  height: 100%;
  vertical-align: middle;
  object-fit: cover;
  border-radius: 10px;
}

.trending-tody-content {
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .trending-tody-content {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .trending-tody-content {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .trending-tody-content {
    margin-left: 0px;
  }
}

.trending-tody-post-author {
  color: #9b9ea1;
  margin-bottom: 10px;
  font-size: 18px;
}

.trending-tody-post-author a {
  color: #5974ff;
}

@media only screen and (max-width: 479px) {
  .trending-tody-post-author {
    font-size: 12px;
  }
}

.trending-tody-post-title {
  margin-bottom: 20px;
  font-size: 52px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .trending-tody-post-title {
    font-size: 42px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .trending-tody-post-title {
    font-size: 36px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .trending-tody-post-title {
    font-size: 26px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .trending-tody-post-title {
    font-size: 26px;
  }
}

.trending-tody-content .dec {
  font-size: 18px;
}

.trending-tody-post-meta {
  display: flex;
  align-items: center;
  font-size: 17px;
}

.trending-tody-post-meta span {
  position: relative;
  padding-right: 15px;
  margin-right: 15px;
  font-size: 13px;
  font-weight: 600;
}

.trending-tody-post-meta span::after {
  position: absolute;
  content: "";
  right: -5px;
  top: 50%;
  height: 4px;
  width: 4px;
  background: #5974ff;
  border-radius: 50000px;
  transform: translateY(-50%);
}

.trending-tody-post-meta span a:hover {
  color: #5974ff;
}

.trending-tody-post-meta span:last-child {
  padding-right: 0;
  margin-right: 0;
}

.trending-tody-post-meta span:last-child::after {
  display: none;
}

.trending-tody-swiper-pagination {
  margin-top: 80px;
  text-align: center;
}

.trending-tody-swiper-pagination .swiper-pagination-bullet {
  height: 12px;
  width: 12px;
  border-radius: 5000px;
  background-color: #0f034a;
  margin: 10px;
}

.trending-tody-swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #5974ff;
  border-radius: 5000px;
}

@media only screen and (max-width: 767px) {
  .trending-tody-swiper-pagination {
    margin-top: 40px;
  }
}

.trending-tody-two-box {
  background-color: #ffffff;
  padding: 43px 40px;
  border-radius: 15px;
  transition: 0.3s ease-in-out;
}

.trending-tody-two-box .trending-tody-two-post-title {
  font-size: 18px;
}

.trending-tody-two-box:hover {
  background-color: #5138ee;
}

.trending-tody-two-box:hover .trending-tody-two-post-author {
  color: #ffffff;
}

.trending-tody-two-box:hover .trending-tody-two-post-author a {
  color: #ffffff;
}

.trending-tody-two-box:hover .trending-tody-two-post-title {
  color: #ffffff;
}

.trending-tody-two-box:hover .trending-tody-two-post-meta {
  color: #ffffff;
}

.trending-tody-two-box:hover .trending-tody-two-post-meta span::after {
  background: #ffffff;
}

.trending-tody-two-post-author {
  color: #9b9ea1;
  margin-bottom: 12px;
  font-weight: 500;
  transition: 0s ease-in-out;
}

.trending-tody-two-post-author a {
  color: #0f034a;
  transition: 0s ease-in-out;
}

@media only screen and (max-width: 479px) {
  .trending-tody-two-post-author {
    font-size: 12px;
  }
}

.trending-tody-two-post-title {
  color: #0f034a;
}

.trending-tody-two-post-title a {
  transition: 0s ease-in-out;
}

.trending-tody-two-post-title a:hover {
  color: #ffffff;
}

.trending-tody-two-post-meta {
  display: flex;
  align-items: center;
  margin-top: 20px;
  color: #7e7e7e;
}

.trending-tody-two-post-meta span {
  position: relative;
  padding-right: 15px;
  margin-right: 15px;
  font-size: 13px;
  font-weight: 500;
}

.trending-tody-two-post-meta span::after {
  position: absolute;
  content: "";
  right: -5px;
  top: 50%;
  height: 4px;
  width: 4px;
  background: #0f034a;
  border-radius: 50000px;
  transform: translateY(-50%);
}

.trending-tody-two-post-meta span a {
  transition: 0s ease-in-out;
}

.trending-tody-two-post-meta span a:hover {
  color: #5974ff;
}

.trending-tody-two-post-meta span:last-child {
  padding-right: 0;
  margin-right: 0;
}

.trending-tody-two-post-meta span:last-child::after {
  display: none;
}

.trending-tody-two-slider-navigation {
  display: flex;
  justify-content: flex-end;
}

.trending-tody-two-slider-navigation .navigation-button {
  color: #0f034a;
  font-size: 24px;
  height: 20px;
  line-height: 20px;
  background-color: transparent;
  border-radius: 0;
}

.trending-tody-two-slider-navigation .navigation-button.trending-tody-button-prev {
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid #dadada;
}

/*==================================
    14. From Following Area Css
====================================*/
.from-following-hader-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  margin-bottom: 40px;
}

@media only screen and (max-width: 575px) {
  .from-following-hader-area {
    flex-direction: column;
    align-items: flex-start;
  }
  .from-following-hader-area .section-title {
    margin-bottom: 20px;
  }
}

.from-following-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -0.5rem;
}

.from-following-left-side {
  max-width: 873px;
  flex-grow: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .from-following-left-side {
    max-width: 650px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .from-following-left-side {
    min-width: 100%;
    max-width: 100%;
  }
}

.from-following-right-side {
  max-width: 335px;
  flex-grow: 1;
}

.following-post-thum {
  display: block;
}

.following-post-thum img {
  border-radius: 15px;
}

.single-following-post {
  margin-bottom: 40px;
}

.following-blog-post-top {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 25px;
}

@media only screen and (max-width: 479px) {
  .following-blog-post-top {
    margin-bottom: 10px;
  }
}

.following-blog-post-category {
  margin-right: 25px;
}

.following-blog-post-category a {
  min-width: 100px;
  background: #ffebdf;
  text-align: center;
  padding: 4px 5px;
  border-radius: 10px;
}

@media only screen and (max-width: 479px) {
  .following-blog-post-category {
    margin-right: 15px;
  }
  .following-blog-post-category a {
    min-width: 40px;
    font-size: 12px;
    padding: 2px 5px;
  }
}

.following-blog-post-author {
  color: #9b9ea1;
}

.following-blog-post-author a {
  color: #000000;
}

@media only screen and (max-width: 479px) {
  .following-blog-post-author {
    font-size: 12px;
  }
}

.following-blog-post-title {
  margin-bottom: 15px;
}

.following-blog-post-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.following-meta-left-side span {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
  font-size: 13px;
}

.following-meta-left-side span::after {
  position: absolute;
  content: "";
  right: -0px;
  top: 50%;
  height: 4px;
  width: 4px;
  background: #000000;
  border-radius: 50000px;
  transform: translateY(-50%);
}

.following-meta-left-side span a:hover {
  color: #ffc4a0;
}

.following-meta-left-side span:last-child {
  padding-right: 0;
  margin-right: 0;
}

.following-meta-left-side span:last-child::after {
  display: none;
}

.following-author-area {
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  text-align: center;
  padding: 30px 15px;
}

.following-author-area .author-image {
  border: 1px solid #f3f3f3;
  display: inline-block;
  border-radius: 50000px;
  padding: 11px;
  margin-bottom: 25px;
}

.following-author-area .author-title {
  margin-bottom: 20px;
}

.following-author-area .author-details p {
  margin-bottom: 20px;
}

.following-author-area .author-post-share {
  margin-bottom: 30px;
}

.following-add-banner {
  margin-top: 40px;
}

/*==================================
    15. Trending Topic Area Css
====================================*/
.trending-topic-section-title {
  max-width: 232px;
  padding: 0 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .trending-topic-section-title {
    max-width: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .trending-topic-section-title {
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .trending-topic-section-title {
    max-width: 100%;
  }
}

.trending-topic-item-wrap {
  max-width: 968px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .trending-topic-item-wrap {
    max-width: 758px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .trending-topic-item-wrap {
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .trending-topic-item-wrap {
    max-width: 100%;
  }
}

.single-trending-topic-item {
  text-align: center;
}

.single-trending-topic-item a {
  display: block;
  min-width: 120px;
  max-width: 100%;
  position: relative;
}

.single-trending-topic-item a img {
  border-radius: 15px;
  width: 100%;
}

.single-trending-topic-item a .title {
  position: absolute;
  bottom: 20px;
  text-align: center;
  width: 100%;
  color: #fff;
}

.trending-topic-navigation {
  margin-bottom: 20px;
}

.trending-topic-navigation .navigation-button {
  background-color: #313438;
  color: #ffffff;
}

.trending-topic-navigation .navigation-button:hover {
  background-color: #ffc4a0;
}

/*==================================
    16. Newsletter Area Css
====================================*/
.newsletter-subscribe-inner {
  background-color: #fff;
  padding: 70px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .newsletter-subscribe-inner {
    padding: 70px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .newsletter-subscribe-inner {
    padding: 60px 30px;
  }
}

@media only screen and (max-width: 575px) {
  .newsletter-subscribe-inner {
    padding: 60px 20px;
  }
}

.newsletter-input-box {
  display: flex;
  z-index: 1;
  position: relative;
}

.newsletter-input-box .newsletter-input {
  max-width: 450px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-right: 20px;
  padding: 5px 15px;
  font-weight: 500;
  height: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .newsletter-input-box .newsletter-input {
    max-width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .newsletter-input-box .newsletter-input {
    max-width: 100%;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .newsletter-input-box {
    flex-direction: column;
  }
}

.newsletter-inner-image .newsletter-image-01 {
  position: absolute;
  bottom: 0;
  left: 18%;
  z-index: 0;
}

.newsletter-inner-image .newsletter-image-02 {
  position: absolute;
  bottom: 0;
  right: 34px;
  z-index: 0;
}

.related-newsletter-box {
  background: url("../images/banners/home-two-newsletter-bg.jpg");
  border-radius: 10px;
  background-size: cover;
  background-position: center;
}

.related-newsletter-inner-box {
  max-width: 730px;
  margin: auto;
  text-align: center;
  padding: 80px 15px;
}

.related-newsletter-inner-box .title {
  color: #ffffff;
  font-size: 44px;
  font-weight: bold;
}

.related-newsletter-inner-box .title .normal-width {
  font-weight: 500;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .related-newsletter-inner-box .title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .related-newsletter-inner-box .title {
    font-size: 30px;
  }
}

.related-newsletter-three-box {
  background: url("../images/banners/home-three-newsletter-bg.jpg");
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.related-newsletter-three-inner-box {
  max-width: 894px;
  text-align: left;
  padding: 80px 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .related-newsletter-three-inner-box {
    padding: 60px 40px;
  }
}

@media only screen and (max-width: 575px) {
  .related-newsletter-three-inner-box {
    padding: 60px 40px 100px;
  }
}

.related-newsletter-three-inner-box .title {
  color: #ffffff;
  font-weight: bold;
  font-size: 44px;
}

.related-newsletter-three-inner-box .title .normal-width {
  font-weight: 500;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .related-newsletter-three-inner-box .title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .related-newsletter-three-inner-box .title {
    font-size: 30px;
  }
}

.subscribe-today-update {
  position: absolute;
  bottom: 60px;
  right: 70px;
  width: 250px;
}

.subscribe-today-update .today-update-text {
  font-size: 28px;
  color: #ffffff;
  font-weight: 400;
}

.subscribe-today-update .today-update-text span {
  font-weight: 600;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe-today-update {
    right: 40px;
  }
  .subscribe-today-update .today-update-text {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .subscribe-today-update {
    right: 20px;
  }
  .subscribe-today-update .today-update-text {
    font-size: 18px;
  }
}

@media only screen and (max-width: 575px) {
  .subscribe-today-update {
    left: 45px;
    bottom: 30px;
    width: 150px;
  }
  .subscribe-today-update .today-update-text {
    font-size: 18px;
  }
}

.newsletter-four-box {
  background: url("../images/banners/home-four-newsletter-bg.jpg");
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  background-color: #fff;
  padding: 70px;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .newsletter-four-box {
    padding: 70px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .newsletter-four-box {
    padding: 60px 30px;
  }
}

@media only screen and (max-width: 575px) {
  .newsletter-four-box {
    padding: 60px 20px;
  }
}

.newsletter-four-box .title {
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 44px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .newsletter-four-box .title {
    font-size: 36px;
  }
}

@media only screen and (max-width: 767px) {
  .newsletter-four-box .title {
    font-size: 30px;
  }
}

.newsletter-four-box p {
  color: #ffffff;
  font-size: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .newsletter-four-box p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .newsletter-four-box p {
    font-size: 18px;
  }
}

.newsletter-four-box .newsletter-input-box {
  margin-top: 30px;
  justify-content: center;
}

.newsletter-four-box .newsletter-input-box .newsletter-input {
  background: transparent;
  border: 1px solid #fff;
  color: #ffffff;
  border-radius: 15px;
  padding: 0 30px;
}

.newsletter-four-box .newsletter-input-box .newsletter-input::-webkit-input-placeholder {
  /* Edge */
  color: #ffffff;
}

.newsletter-four-box .newsletter-input-box .newsletter-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff;
}

.newsletter-four-box .newsletter-input-box .newsletter-input::placeholder {
  color: #ffffff;
}

.newsletter-inner-image .newsletter-image-01 {
  position: absolute;
  bottom: 0;
  left: 18%;
  z-index: 0;
}

.newsletter-inner-image .newsletter-image-02 {
  position: absolute;
  bottom: 0;
  right: 34px;
  z-index: 0;
}

/*==================================
    17. Featured Video Area Css
====================================*/
.featured-video-col-8 {
  max-width: 847px;
  flex: 0 0 auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .featured-video-col-8 {
    max-width: 846px;
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .featured-video-col-8 {
    max-width: 100%;
  }
}

.latest-post-col-4 {
  max-width: 363px;
  flex: 0 0 auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .latest-post-col-4 {
    max-width: 360px;
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .latest-post-col-4 {
    max-width: 100%;
  }
}

.featured-video-haader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .featured-video-haader {
    flex-direction: column;
    align-items: flex-start;
  }
}

.featured-video-list .featured-video-list-item {
  padding-right: 40px;
  margin-right: 25px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .featured-video-list .featured-video-list-item {
    padding-right: 25px;
    margin-right: 8px;
  }
}

@media only screen and (max-width: 575px) {
  .featured-video-list .featured-video-list-item {
    margin-top: 20px;
  }
}

.featured-video-list .featured-video-list-item::after {
  position: absolute;
  right: 0;
  top: 50%;
  content: '\eab8';
  font-family: IcoFont;
  transform: translateY(-50%);
}

.featured-video-list .featured-video-list-item .featured-video-link {
  font-weight: 600;
}

.featured-video-list .featured-video-list-item .featured-video-link.active {
  color: #ffc4a0;
}

.featured-video-list .featured-video-list-item:last-child {
  padding-right: 0px;
  margin-right: 0px;
}

.featured-video-list .featured-video-list-item:last-child::after {
  display: none;
}

.single-featured-video-item {
  margin-bottom: 30px;
}

.featured-blog-post-top {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 25px;
}

@media only screen and (max-width: 479px) {
  .featured-blog-post-top {
    margin-bottom: 10px;
  }
}

.featured-blog-post-top .post-meta-right-side {
  margin-left: 30px;
}

@media only screen and (max-width: 767px) {
  .featured-blog-post-top .read-time {
    display: none;
  }
}

/*==================================
    18. Latest Post Area Css
====================================*/
.latest-post-inner-wrap {
  padding: 30px 24px;
  background: #fafafa;
  border-radius: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .latest-post-box {
    display: flex;
    flex-wrap: wrap;
  }
}

.latest-post-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.latest-post-slider-navigation .navigation-button {
  background-color: #222;
  color: #ffffff;
}

.latest-post-slider-navigation .navigation-button:hover {
  background-color: #ffc4a0;
}

.single-latest-post {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .single-latest-post {
    width: 50%;
    padding-right: 15px;
  }
}

.latest-post-thum {
  flex-basis: 0 0 auto;
  min-width: 84px;
}

.latest-post-thum a img {
  border-radius: 10px;
}

.latest-post-content {
  margin-left: 20px;
}

.latest-post-content .title {
  margin-top: 0px;
  font-size: 15px;
}

.latest-post-content .latest-post-meta {
  font-size: 12px;
  margin-top: 10px;
}

.latest-post-content .latest-post-meta span {
  position: relative;
  padding-right: 6px;
  margin-right: 6px;
}

.latest-post-content .latest-post-meta span::after {
  position: absolute;
  content: "";
  right: -0px;
  top: 50%;
  height: 4px;
  width: 4px;
  background: #000000;
  border-radius: 50000px;
  transform: translateY(-50%);
}

.latest-post-content .latest-post-meta span a:hover {
  color: #ffc4a0;
}

.latest-post-content .latest-post-meta span:last-child {
  padding-right: 0;
  margin-right: 0;
}

.latest-post-content .latest-post-meta span:last-child::after {
  display: none;
}

.stay-in-touch-area {
  margin-top: 30px;
  padding: 30px 24px;
  background: #fafafa;
  border-radius: 10px;
  text-align: center;
}

.stay-in-touch-box {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}

.single-touch-col {
  width: 33.333%;
  flex: 0 0 auto;
  padding-left: 5px;
  padding-right: 5px;
}

.single-touch {
  background-color: #ebebeb;
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
  border-radius: 10px;
  padding: 14px 10px;
}

.single-touch.facebook .touch-socail-icon {
  background: #4867AA;
}

.single-touch.twitter .touch-socail-icon {
  background: #1DA1F2;
}

.single-touch.behance .touch-socail-icon {
  background: #1869FF;
}

.single-touch.youtube .touch-socail-icon {
  background: #FE0000;
}

.single-touch.dribbble .touch-socail-icon {
  background: #EA4C8A;
}

.single-touch.linkedin .touch-socail-icon {
  background: #007BB6;
}

.single-touch p {
  color: #000000;
  font-size: 12px;
}

.single-touch:hover.facebook {
  background: #4867AA;
}

.single-touch:hover.facebook .touch-socail-icon {
  color: #4867AA;
  background: #ffffff;
}

.single-touch:hover.twitter {
  background: #1DA1F2;
}

.single-touch:hover.twitter .touch-socail-icon {
  color: #1DA1F2;
  background: #ffffff;
}

.single-touch:hover.behance {
  background: #1869FF;
}

.single-touch:hover.behance .touch-socail-icon {
  color: #1869FF;
  background: #ffffff;
}

.single-touch:hover.youtube {
  background: #FE0000;
}

.single-touch:hover.youtube .touch-socail-icon {
  color: #FE0000;
  background: #ffffff;
}

.single-touch:hover.dribbble {
  background: #EA4C8A;
}

.single-touch:hover.dribbble .touch-socail-icon {
  color: #EA4C8A;
  background: #ffffff;
}

.single-touch:hover.linkedin {
  background: #007BB6;
}

.single-touch:hover.linkedin .touch-socail-icon {
  color: #007BB6;
  background: #ffffff;
}

.single-touch:hover p {
  color: #ffffff;
}

.touch-socail-icon {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  margin: auto;
  margin-bottom: 5px;
  border-radius: 100%;
  color: #ffffff;
}

/*==================================
    19. Recent Reading Area Css
====================================*/
.recent-reading-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
}

@media only screen and (max-width: 575px) {
  .recent-reading-header {
    flex-direction: column;
    align-items: self-end;
  }
}

.recent-reading-header .recent-article-date {
  display: flex;
  align-items: center;
}

.recent-reading-header .recent-article-date .date-button {
  margin-left: 15px;
  height: 48px;
  width: 48px;
  line-height: 48px;
  text-align: center;
  background-color: #f8f7fc;
  border-radius: 10px;
}

.single-recent-reading-post {
  display: flex;
  margin-top: 40px;
}

.recent-reading-post-thum {
  width: 120px;
  flex: 0 0 auto;
}

.recent-reading-post-author {
  color: #9b9ea1;
  margin-bottom: 10px;
}

.recent-reading-post-author a {
  color: #000000;
}

.recent-reading-post-content {
  margin-left: 25px;
}

@media only screen and (max-width: 575px) {
  .recent-reading-post-content {
    margin-left: 10px;
  }
}

.recent-reading-post-meta {
  font-size: 12px;
  margin-top: 10px;
}

.recent-reading-post-meta span {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
}

.recent-reading-post-meta span::after {
  position: absolute;
  content: "";
  right: -0px;
  top: 50%;
  height: 4px;
  width: 4px;
  background: #000000;
  border-radius: 50000px;
  transform: translateY(-50%);
}

.recent-reading-post-meta span a:hover {
  color: #ffc4a0;
}

.recent-reading-post-meta span:last-child {
  padding-right: 0;
  margin-right: 0;
}

.recent-reading-post-meta span:last-child::after {
  display: none;
}

/*==================================
    20. Archive Search Area Css
====================================*/
.archive-search-box {
  position: relative;
  margin-top: 20px;
}

.archive-search-box .search-input {
  width: 100%;
  background-color: #f8f7fc;
  border-radius: 10px;
  position: relative;
  border: none;
  font-weight: 500;
  padding: 16px 40px 16px 30px;
}

.archive-search-box .search-button {
  position: absolute;
  top: 50%;
  right: 0;
  background-color: transparent;
  color: #5974ff;
  padding: 10px 15px;
  border: none;
  font-size: 20px;
  transform: translateY(-50%);
}

.archive-post-inner-wrap {
  border-radius: 10px;
  max-width: 300px;
  margin-left: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .archive-post-inner-wrap {
    max-width: 100%;
    margin-top: 60px;
  }
}

.single-archive-post {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.archive-post-thum {
  flex-basis: 0 0 auto;
  min-width: 84px;
}

.archive-post-thum a img {
  border-radius: 10px;
}

.archive-post-content {
  margin-left: 20px;
}

.archive-post-content .title {
  margin-top: 0px;
  font-size: 15px;
}

.archive-post-content .archive-post-meta {
  font-size: 12px;
  margin-top: 10px;
}

.archive-post-content .archive-post-meta span {
  position: relative;
  padding-right: 6px;
  margin-right: 6px;
}

.archive-post-content .archive-post-meta span::after {
  position: absolute;
  content: "";
  right: -0px;
  top: 50%;
  height: 4px;
  width: 4px;
  background: #000000;
  border-radius: 50000px;
  transform: translateY(-50%);
}

.archive-post-content .archive-post-meta span a:hover {
  color: #ffc4a0;
}

.archive-post-content .archive-post-meta span:last-child {
  padding-right: 0;
  margin-right: 0;
}

.archive-post-content .archive-post-meta span:last-child::after {
  display: none;
}

.follow-us-box {
  margin-top: 20px;
}

.single-follow-col {
  margin-bottom: 15px;
}

.single-follow {
  background-color: #f8f7fc;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-radius: 15px;
  padding: 17px 20px;
}

.single-follow i {
  margin-right: 5px;
}

.single-follow .socail-title {
  font-weight: 600;
}

.single-follow:hover {
  background-color: #5974ff;
  color: #ffffff;
}

.single-follow:hover .follow-share-onover .follow-title {
  opacity: 0;
  visibility: hidden;
}

.single-follow:hover .follow-share-onover .follow-hover {
  opacity: 1;
  visibility: visible;
  color: #ffffff;
}

.single-follow:hover .follow-socail-icon {
  color: #ffffff;
}

.follow-share-onover {
  position: relative;
}

.follow-share-onover p {
  margin-bottom: 0;
  position: absolute;
  right: 0;
  transition: all 0.3s ease-in;
  color: #000000;
}

.follow-share-onover .follow-title {
  opacity: 1;
  visibility: visible;
}

.follow-share-onover .follow-hover {
  opacity: 0;
  visibility: hidden;
}

/*==================================
    21. Add Banner Area Css
====================================*/
.bottom-add-banner-box {
  position: relative;
}

.bottom-add-banner-boxa {
  display: block;
}

@media only screen and (max-width: 767px) {
  .bottom-add-banner-box img {
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
  }
}

.bottom-add-text {
  font-size: 25px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 50px;
  display: inline-block;
}

.bottom-add-text span {
  display: block;
  font-size: 32px;
  text-align: left;
  margin-top: 3px;
}

@media only screen and (max-width: 767px) {
  .bottom-add-text {
    font-size: 16px;
  }
  .bottom-add-text span {
    font-size: 18px;
  }
}

/*==================================
    22. Most Populer Area Css
====================================*/
.single-most-populer-item {
  margin-top: 40px;
}

.most-populer-thum {
  display: block;
}

.most-populer-thum img {
  border-radius: 10px;
  width: 100%;
}

.most-populer-content {
  margin-top: 25px;
}

.most-populer-content .title {
  color: #0f034a;
  margin-top: 10px;
}

.most-populer-content .most-populer-post-meta {
  margin-top: 10px;
}

.most-populer-content .most-populer-post-meta > span {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
  font-size: 13px;
  color: #0f034a;
}

.most-populer-content .most-populer-post-meta > span::after {
  position: absolute;
  content: "";
  right: -0px;
  top: 50%;
  height: 4px;
  width: 4px;
  background: #000000;
  border-radius: 50000px;
  transform: translateY(-50%);
}

.most-populer-content .most-populer-post-meta > span:last-child {
  padding-right: 0;
  margin-right: 0;
}

.most-populer-content .most-populer-post-meta > span:last-child::after {
  display: none;
}

.most-populer-post-author {
  color: #9b9ea1;
  font-weight: 600;
}

.most-populer-post-author a {
  color: #0f034a;
}

.most-popular-slider-navigation {
  display: flex;
  justify-content: flex-end;
}

.most-popular-slider-navigation .navigation-button {
  width: 50px;
  height: 50px;
  line-height: 45px;
  border: 1px solid #e7e5ed;
  text-align: center;
  font-size: 25px;
  border-radius: 10px;
  color: #0f034a;
  transition: all 0.3s ease-in-out;
}

.most-popular-slider-navigation .navigation-button.popular-swiper-button-next {
  margin-left: 10px;
}

.most-popular-slider-navigation .navigation-button:hover {
  border: 1px solid #ff7d6b;
  background-color: #ff7d6b;
  color: #ffffff;
}

.special-banner-blog-post .single-special-banner-post {
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 30px;
}

.special-banner-blog-post .single-special-banner-post:last-child {
  border-bottom: none;
}

/*==================================
    23. Recent Article Area Css
====================================*/
.recent-article-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recent-article-header .recent-article-date {
  display: flex;
  align-items: center;
}

.recent-article-header .recent-article-date .date-button {
  margin-left: 15px;
  height: 48px;
  width: 48px;
  line-height: 48px;
  text-align: center;
  background-color: #f4f4f4;
  border-radius: 10px;
}

@media only screen and (max-width: 575px) {
  .recent-article-header {
    flex-direction: column;
  }
  .recent-article-header .recent-article-date {
    margin-top: 10px;
  }
}

.section-border-bottom {
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 30px;
}

.recent-article-header-two {
  padding-top: 60px;
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .recent-article-header-two {
    flex-direction: column;
  }
}

.recent-article-header-two .input-search-box {
  position: relative;
  width: 340px;
}

.recent-article-header-two .input-search-box .input {
  border: 1px solid #ddd;
  border-radius: 15px;
  position: relative;
  height: 60px;
  padding: 15px 50px 15px 30px;
  font-weight: 500;
  width: 100%;
}

.recent-article-header-two .input-search-box .submit-button {
  position: absolute;
  right: 15px;
  height: 60px;
  background: transparent;
  border: none;
}

@media only screen and (max-width: 767px) {
  .recent-article-header-two .input-search-box {
    width: 300px;
    margin-top: 30px;
  }
}

.single-recent-article-item {
  margin-top: 45px;
  display: flex;
}

@media only screen and (max-width: 575px) {
  .single-recent-article-item {
    flex-direction: column;
  }
}

.recent-article-thum {
  display: block;
  max-width: 360px;
  flex: 0 0 auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .recent-article-thum {
    max-width: 300px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .recent-article-thum {
    max-width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .recent-article-thum {
    max-width: 200px;
  }
}

@media only screen and (max-width: 575px) {
  .recent-article-thum {
    max-width: 100%;
  }
}

.recent-article-thum img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  object-fit: cover;
}

.recent-article-content {
  margin-left: 35px;
  margin-top: 15px;
}

@media only screen and (max-width: 575px) {
  .recent-article-content {
    margin-left: 0px;
    margin-top: 30px;
  }
}

.recent-article-content .title {
  color: #0f034a;
  margin-top: 10px;
}

.recent-article-content .recent-article-post-meta {
  margin-top: 10px;
}

.recent-article-content .recent-article-post-meta > span {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
  font-size: 13px;
  color: #0f034a;
}

.recent-article-content .recent-article-post-meta > span::after {
  position: absolute;
  content: "";
  right: -0px;
  top: 50%;
  height: 4px;
  width: 4px;
  background: #000000;
  border-radius: 50000px;
  transform: translateY(-50%);
}

.recent-article-content .recent-article-post-meta > span:last-child {
  padding-right: 0;
  margin-right: 0;
}

.recent-article-content .recent-article-post-meta > span:last-child::after {
  display: none;
}

.recent-article-post-author {
  color: #9b9ea1;
  font-weight: 600;
}

.recent-article-post-author a {
  color: #0f034a;
}

.recent-post-right-area {
  padding-left: 26px;
}

/*==================================
    24. Trusted Partners Area Css
====================================*/
.trusted-partners-box {
  padding: 80px 100px;
  background-color: #f4eaff;
  border-radius: 15px;
}

.trusted-partners-box .trusted-partners-slider-wrap {
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .trusted-partners-box {
    padding: 60px 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .trusted-partners-box {
    padding: 60px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .trusted-partners-box {
    padding: 60px 30px;
  }
}

.trusted-partners-area {
  padding: 60px 0;
}

.partners-swiper-pagination {
  margin-top: 40px;
  text-align: center;
}

.partners-swiper-pagination .swiper-pagination-bullet {
  height: 14px;
  width: 14px;
  border-radius: 100%;
  background-color: #8b61b7;
  margin: 8px;
}

.partners-swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #0f034a;
  border-radius: 100%;
}

/*==================================
    25. Platform Area Css
====================================*/
.single-platform-box {
  background-color: #fafafa;
  padding: 40px;
  border-radius: 10px;
  margin-top: 30px;
}

@media only screen and (max-width: 575px) {
  .single-platform-box {
    padding: 40px 20px;
  }
}

.platform-icon {
  background-color: #ffc4a0;
  display: inline-block;
  height: 90px;
  width: 90px;
  line-height: 90px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 20px;
}

.platform-content .title {
  margin-bottom: 20px;
}

.platform-d-flex {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .platform-d-flex {
    flex-direction: column;
  }
}

.platform-content-box {
  max-width: 300px;
  padding-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .platform-content-box {
    max-width: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .platform-content-box {
    max-width: 100%;
    margin-bottom: 30px;
  }
}

.plateform-image-box {
  margin-left: 30px;
}

@media only screen and (max-width: 767px) {
  .plateform-image-box {
    margin-left: 0px;
  }
}

.bunzo-row {
  display: flex;
  align-items: center;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .bunzo-row {
    flex-direction: column;
    justify-content: flex-start;
    border-bottom: none;
  }
}

.bunzo-col-6 {
  width: 50%;
  flex: 0 0 auto;
  border-left: 1px solid #ddd;
  height: 100%;
}

.bunzo-col-6:first-child {
  border-left: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .bunzo-col-6 {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #ddd;
  }
}

.bunzo-history-title {
  font-size: 62px;
  font-weight: 300;
  margin-right: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.bunzo-history-title .f-w-bold {
  font-weight: 600;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bunzo-history-title {
    font-size: 42px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bunzo-history-title {
    font-size: 42px;
  }
}

@media only screen and (max-width: 767px) {
  .bunzo-history-title {
    font-size: 22px;
  }
}

.single-history-item {
  border-bottom: 1px solid #ddd;
  padding: 60px 0 60px 90px;
}

.single-history-item:last-child {
  border-bottom: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .single-history-item {
    padding: 30px 30px 30px 30px;
    border-right: 1px solid #ddd;
  }
}

.plateforem-image {
  position: relative;
}

.plateforem-image .platform-box-button {
  position: absolute;
  width: 100%;
  bottom: 30px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

/*==================================
    26. Team Area Css
====================================*/
.single-team-area {
  position: relative;
  overflow: hidden;
  margin-top: 40px;
}

.single-team-area .team-thum img {
  width: 100%;
}

.single-team-area:hover .team-content {
  opacity: 1;
  visibility: visible;
}

.single-team-area:hover .team-share-top {
  margin-top: 20px;
}

.single-team-area:hover .team-member-info {
  margin-bottom: 20px;
}

.team-content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.9) 0, rgba(253, 195, 158, 0.9) 100%);
}

.team-share-top {
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
  transition: all 0.4s ease-in-out;
}

.shate-action-button {
  margin-left: 20px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  font-weight: 600;
}

.shate-action-button:hover {
  background-color: #222;
  color: #fff;
}

.team-social-share {
  text-align: right;
  margin-right: 20px;
}

.team-social-share li {
  display: inline-block;
  margin: 0 3px;
}

.team-social-share li a {
  height: 40px;
  width: 40px;
  line-height: 40px;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
}

.team-social-share li a:hover {
  background-color: #222;
  color: #fff;
}

.team-member-info {
  margin-bottom: 0px;
  transition: all 0.3s ease-in-out;
}

.team-member-info .name-title {
  color: #ffffff;
}

.team-member-info .desination {
  color: #ffc4a0;
}

/*==================================
    27. Blog Details Area Css
====================================*/
.blog-details-col-8 {
  max-width: 847px;
  flex: 0 0 auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-col-8 {
    max-width: 603px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .blog-details-col-8 {
    max-width: 100%;
  }
}

.blog-details-col-4 {
  max-width: 363px;
  flex: 0 0 auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-col-4 {
    max-width: 363px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .blog-details-col-4 {
    max-width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-col-4 .single-latest-post {
    width: 100%;
    padding-right: 0px;
  }
}

.blog-details-meta-box {
  margin-top: 20px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.blog-details-meta-box .post-meta-left-side, .blog-details-meta-box .post-mid-side {
  display: flex;
  align-items: center;
}

.blog-details-meta-box .post-mid-side span {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
  font-size: 13px;
}

.blog-details-meta-box .post-mid-side span::after {
  position: absolute;
  content: "";
  right: -0px;
  top: 50%;
  height: 4px;
  width: 4px;
  background: #000000;
  border-radius: 50000px;
  transform: translateY(-50%);
}

.blog-details-meta-box .post-mid-side span a:hover {
  color: #ffc4a0;
}

.blog-details-meta-box .post-mid-side span:last-child {
  padding-right: 0;
  margin-right: 0;
}

.blog-details-meta-box .post-mid-side span:last-child::after {
  display: none;
}

@media only screen and (max-width: 767px) {
  .blog-details-meta-box {
    flex-direction: column;
  }
}

.blockquote-box {
  background-color: #fafafa;
  padding: 60px 60px;
  text-align: center;
  position: relative;
  margin-top: 30px;
}

.blockquote-box::before {
  color: #ffc4a0;
  font-size: 106px;
  position: absolute;
  content: "“";
  height: auto;
  width: 105px;
  line-height: 100px;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.blockquote-box .blockquote-text {
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
}

@media only screen and (max-width: 767px) {
  .blockquote-box {
    padding: 60px 30px;
  }
  .blockquote-box .blockquote-text {
    font-size: 16px;
  }
}

.blog-details-tag-and-share-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

@media only screen and (max-width: 767px) {
  .blog-details-tag-and-share-area {
    flex-direction: column;
    align-items: flex-start;
  }
  .blog-details-tag-and-share-area .social-share-area {
    margin-top: 30px;
  }
}

.related-post-thum img {
  width: 100%;
}

.comment-form-area {
  margin-top: 30px;
}

.comment-form-area .single-input {
  margin-bottom: 20px;
}

.comment-form-area .single-input input,
.comment-form-area .single-input textarea {
  border: 1px solid #efefef;
  border-radius: 5px;
  width: 100%;
  padding: 15px 30px;
  background-color: #fafafa;
}

.comment-form-area .single-input textarea {
  height: 280px;
}

.blog-details-two-header {
  max-width: 800px;
  text-align: center;
  margin: auto;
  margin-top: 40px;
}

.blog-details-two-post-title {
  margin-bottom: 25px;
  font-size: 32px;
  font-weight: bold;
  color: #0f034a;
}

.blog-details-two-post-meta {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 479px) {
  .blog-details-two-post-meta {
    flex-wrap: wrap;
  }
}

.blog-details-two-post-meta span {
  position: relative;
  padding-right: 15px;
  margin-right: 15px;
  font-size: 13px;
  font-weight: 600;
}

.blog-details-two-post-meta span::after {
  position: absolute;
  content: "";
  right: -5px;
  top: 50%;
  height: 4px;
  width: 4px;
  background: #091d40;
  border-radius: 50000px;
  transform: translateY(-50%);
}

.blog-details-two-post-meta span a:hover {
  color: #5974ff;
}

.blog-details-two-post-meta span:last-child {
  padding-right: 0;
  margin-right: 0;
}

.blog-details-two-post-meta span:last-child::after {
  display: none;
}

.blog-details-two-post-author {
  color: #9b9ea1;
}

.blog-details-two-post-author a {
  color: #091d40;
}

@media only screen and (max-width: 479px) {
  .blog-details-two-post-author {
    font-size: 12px;
  }
}

.blog-details-two-tags a {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  height: 40px;
  border-radius: 10px;
  line-height: 40px;
}

.blog-details-two-post-text .title {
  color: #0a1e43;
}

.table-content-list .table-content-item {
  position: relative;
  padding: 20px;
  padding-left: 50px;
  background-color: transparent;
  border-radius: 15px;
  border: 1px solid #eeeeee;
  margin-bottom: 10px;
  font-weight: 600;
  color: #5138ee;
  transition: all 0.3s ease-in-out;
}

.table-content-list .table-content-item:hover {
  background-color: #eeee;
}

.table-content-list .table-content-item::before {
  content: '\ea98';
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-family: IcoFont;
  color: #0a1e43;
}

.blockquote-box-two {
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: #5138ee;
  padding: 80px 60px;
  text-align: center;
}

.blockquote-box-two h4 {
  font-size: 44px;
  color: #fff;
  font-weight: 500;
}

.blockquote-box-two h4 .bold {
  font-weight: 600;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blockquote-box-two {
    padding: 60px 30px;
  }
  .blockquote-box-two h4 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .blockquote-box-two {
    padding: 60px 30px;
  }
  .blockquote-box-two h4 {
    font-size: 26px;
  }
}

.blog-post-author {
  color: #9b9ea1;
  margin-left: 15px;
}

.blog-post-author a {
  color: #000000;
}

.blog-details-two-share-area {
  margin-top: 40px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 50px;
}

@media only screen and (max-width: 767px) {
  .blog-details-two-share-area {
    flex-direction: column;
    padding: 15px 10px;
  }
  .blog-details-two-share-area .share-title {
    margin-bottom: 20px;
  }
}

.related-post-two-slider-navigation {
  display: flex;
  justify-content: flex-end;
}

.related-post-two-slider-navigation .navigation-button {
  width: 50px;
  height: 50px;
  line-height: 45px;
  border: 2px solid #e0dfe6;
  text-align: center;
  background-color: transparent;
  font-size: 25px;
  border-radius: 10px;
  color: #0f034a;
  transition: all 0.3s ease-in-out;
}

.related-post-two-slider-navigation .navigation-button.popular-swiper-button-next {
  margin-left: 10px;
}

.related-post-two-slider-navigation .navigation-button:hover {
  border: 1px solid #5138ee;
  background-color: #5138ee;
  color: #ffffff;
}

.comment-list-wrapper {
  margin-bottom: 60px;
}

.comment-list-wrapper .widget-title {
  color: #0a1e43;
}

.comment-list {
  margin: 0;
  padding: 0;
}

.comment-list .comment-2, .comment-list .comment-reply-wrap {
  border: 1px solid #eeeeee;
  padding: 30px;
  border-radius: 10px;
}

.comment-list .comment-author-info {
  display: flex;
  align-items: center;
}

.comment-list .comment {
  list-style-type: none;
  padding: 10px 0;
}

.comment-list .comment:last-child {
  padding-bottom: 0;
}

.comment-list .comment-author img {
  border-radius: 50px;
}

.comment-list .comment-content {
  position: relative;
  overflow: hidden;
  margin-left: 20px;
  width: 100%;
}

.comment-list .meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .comment-list .meta {
    flex-direction: column;
    align-items: flex-start;
  }
}

.comment-list .meta .fn {
  font-size: 22px;
  text-transform: uppercase;
  color: #0a1e43;
  display: block;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .comment-list .meta .fn {
    font-size: 18px;
  }
}

.comment-list .meta .comment-datetime {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #ababab;
  margin-top: 10px;
}

.comment-list .meta .separator {
  padding: 0 10px;
}

.comment-list .meta .time {
  color: #5138ee;
}

.comment-list .comment-author.vcard {
  padding: 8px;
  border: 1px solid #dfeee5;
  border-radius: 100%;
}

.comment-list .comment-actions a {
  margin-right: 20px;
  font-weight: 500;
  color: #333;
}

.comment-list .comment-actions a:hover {
  color: #5138ee;
}

.comment-list .comment-reply-link {
  background-color: #f8f8f8;
  height: 45px;
  line-height: 45px;
  color: #5138ee;
  font-weight: 600;
  border-radius: 10px;
  padding: 0 20px;
}

.comment-list .comment-reply-link i {
  margin-right: 10px;
}

@media only screen and (max-width: 767px) {
  .comment-list .comment-reply-link {
    margin-top: 5px;
  }
}

.comment-list .children {
  margin: 20px 0 20px 100px;
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .comment-list .children {
    margin: 40px 0 20px 30px;
  }
}

.comment-list .children li + li {
  margin-top: 0px;
}

.comment-list .comment-text {
  margin-top: 15px;
}

.comment-submit-btn .ht-btn {
  padding: 0 54px;
}

/*==================================
    28. Author Post Area Css
====================================*/
.messonry-button {
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.messonry-button button {
  background-color: transparent;
  border: none;
  font-weight: 600;
  padding-left: 35px;
  margin-left: 30px;
  position: relative;
}

.messonry-button button.is-checked {
  color: #ffc4a0;
}

.messonry-button button::before {
  color: #222;
  content: '';
  height: 6px;
  width: 6px;
  background: #222;
  border-radius: 5000px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0%;
}

.messonry-button button:first-child {
  padding-left: 0px;
  margin-left: 0px;
}

.messonry-button button:first-child::before {
  display: none;
}

.author-blog-post-content .post-right-side {
  display: flex;
}

.author-blog-post-content .post-right-side span {
  position: relative;
  padding-right: 15px;
  margin-right: 15px;
  font-size: 13px;
}

.author-blog-post-content .post-right-side span::after {
  position: absolute;
  content: "";
  right: -0px;
  top: 50%;
  height: 4px;
  width: 4px;
  background: #000000;
  border-radius: 50000px;
  transform: translateY(-50%);
}

.author-blog-post-content .post-right-side span a:hover {
  color: #ffc4a0;
}

.author-blog-post-content .post-right-side span:last-child {
  padding-right: 0;
  margin-right: 0;
}

.author-blog-post-content .post-right-side span:last-child::after {
  display: none;
}

@media only screen and (max-width: 767px) {
  .author-blog-post-content {
    flex-direction: column;
  }
}

.author-blog-post-wrap {
  margin-bottom: 30px;
}

.author-post-bottom-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  margin-top: 20px;
}

.author-blog-thum {
  display: block;
}

.author-blog-thum img {
  width: 100%;
}

.author-post-action-box {
  display: flex;
  padding: 0px 20px;
  margin: 20px 0;
}

@media only screen and (max-width: 575px) {
  .author-post-action-box {
    padding: 0px 0px;
  }
}

.author-post-action-box .author-action {
  margin-right: 10px;
  margin-left: 10px;
}

/*==================================
    29. FAQ Area Css
====================================*/
.faq-box-wrap {
  margin-top: 100px;
  margin-bottom: 100px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.faq-section-title {
  margin-top: 30px;
}

.faq-section-title .title {
  font-size: 62px;
  font-weight: 400;
  margin-top: 100px;
  margin-right: 30px;
}

.faq-section-title .title .bold-text {
  font-weight: 600;
  display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq-section-title .title {
    font-size: 52px;
    margin-top: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq-section-title .title {
    font-size: 48px;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .faq-section-title .title {
    font-size: 42px;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .faq-section-title .title {
    font-size: 32px;
    margin-top: 50px;
  }
}

.faq-content-wrap {
  border-left: 1px solid #ddd;
}

.faq-qustion {
  background: transparent;
  border: none;
  font-weight: 600;
  padding: 40px 0 40px 40px;
  font-size: 20px;
}

@media only screen and (max-width: 575px) {
  .faq-qustion {
    font-size: 14px;
    padding: 20px 0 20px 10px;
  }
}

.faq-qustion::after {
  display: none;
}

.faq-qustion:focus {
  outline: 0;
  box-shadow: none;
}

.faq-qustion .number-of-accordion {
  min-height: 46px;
  min-width: 46px;
  text-align: center;
  line-height: 46px;
  background-color: #f4f4f4;
  border-radius: 10px;
  margin-right: 20px;
  font-size: 15px;
}

.faq-qustion:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
  color: #000000;
}

.faq-qustion:not(.collapsed) .number-of-accordion {
  background-color: #ffc4a0;
  color: #ffffff;
}

.accordion-item {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #ddd;
}

.accordion-item:last-child {
  border-bottom: none;
}

.faq-ans {
  padding: 0 0 40px 100px;
}

@media only screen and (max-width: 575px) {
  .faq-ans {
    padding: 0 0 20px 10px;
  }
}

/*==================================
    30. Contact us page Css
====================================*/
.office-img {
  margin-top: 30px;
  padding: 30px;
  background: #f7f7f7;
  border-radius: 15px;
  position: relative;
}

.office-img img {
  border-radius: 15px;
}

.office-img .office-title {
  position: absolute;
  bottom: 60px;
  text-align: center;
  left: 50%;
  display: inline-block;
  background-color: #333;
  border-radius: 15px;
  transform: translateX(-50%);
  color: #ffffff;
  padding: 10px 40px;
  border: 4px solid rgba(255, 255, 255, 0.8);
}

.single-office-info {
  margin-top: 30px;
  padding: 40px 55px;
  border: 1px solid #ddd;
  border-radius: 15px;
  background-color: transparent;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .single-office-info {
    padding: 30px 25px;
  }
}

.single-office-info .single-contact-info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.single-office-info .single-contact-info:last-child {
  margin-bottom: 0;
}

.single-office-info .single-contact-info .icon {
  min-height: 45px;
  min-width: 45px;
  line-height: 45px;
  border-radius: 10px;
  margin-right: 20px;
  text-align: center;
  background-color: #f4f4f4;
}

.single-office-info-wrap:hover .office-title {
  background-color: #ffc4a0;
  color: #222;
}

.single-office-info-wrap:hover .single-office-info {
  border-color: #ffc4a0;
}

.contact-from .section-title .title {
  font-size: 40px;
}

@media large-mobile {
  .contact-from .section-title .title {
    font-size: 30px;
  }
}

.single-input-box {
  margin-bottom: 20px;
}

.single-input-box input, .single-input-box textarea {
  border: 1px solid #efefef;
  width: 100%;
  padding: 10px 30px;
  border-radius: 10px;
  background-color: #fafafa;
}

.single-input-box textarea {
  height: 200px;
}

.single-input-box:hover input, .single-input-box:hover textarea {
  background-color: transparent;
}

.contact-us-map iframe {
  height: 664px;
  width: 100%;
  border-radius: 15px;
}

/*==================================
    31. Error 404 page Css
====================================*/
.error-404-area {
  padding: 100px 0;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-404-area {
    padding: 80px 0;
  }
}

@media only screen and (max-width: 767px) {
  .error-404-area {
    padding: 60px 0;
  }
}

.error-text {
  text-align: center;
  max-width: 600px;
  margin: auto;
  margin-top: 50px;
}

.error-text h5 {
  color: #ffc4a0;
  margin-bottom: 20px;
}

.error-area-shap {
  position: absolute;
  bottom: 0px;
  left: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-area-shap {
    left: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .error-area-shap {
    display: none;
  }
}

/*==================================
    32. Write Content page Css
====================================*/
.share-thinking-title {
  background-color: #f5f5f5;
  padding: 40px;
  border-radius: 15px;
}

.share-thinking-title .title {
  font-size: 28px;
}

.title-write {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 15px;
  margin-top: 30px;
}

.write-content-box {
  border: 1px solid #f5f5f5;
  padding: 20px;
  border-radius: 15px;
  margin-top: 30px;
}

.post-write-tag a {
  position: relative;
  padding-right: 10px;
}

.post-write-tag a::after {
  content: ",";
}

.post-write-tag a:last-child::after {
  content: "";
}

.post-write-trams {
  padding: 0 20px;
}

.single-trams {
  margin-bottom: 20px;
}

.single-trams .title {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.single-trams .title .form-check-label {
  margin-left: 10px;
  font-weight: 600;
  font-size: 18px;
}

.form-check-input:checked {
  background-color: #ffc4a0;
  border-color: #ffc4a0;
}

.form-check-input:focus {
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.conditon-buttom-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .conditon-buttom-wrap {
    flex-direction: column;
  }
}

.conditon-note {
  color: #8e8f91;
  max-width: 600px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .conditon-note {
    max-width: 500px;
  }
}

.conditon-note .title {
  font-weight: 600;
  color: #000000;
}

/*==================================
    33. login page Css
====================================*/
.login-content form > input {
  width: 100%;
  background-color: #ffffff;
  padding: 1px 20px;
  color: #000000;
  line-height: 47px;
  border: none;
  border-radius: 10px;
  margin-bottom: 25px;
  border: 1px solid #ddd;
}

.remember-wrap {
  display: flex;
  align-items: center;
}

.remember-wrap p {
  margin-bottom: 0;
}

.remember-forget-wrap {
  display: flex;
  justify-content: space-between;
}
